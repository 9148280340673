import throttle from 'lodash/throttle'
import { smallerThan } from 'Helpers'

export default class ProductIndex {
  /**
   * Creates an instance of ProductIndex
   *
   * @memberof ProductIndex
   */
  constructor () {
    this.$title = document.querySelector('.Product-title')
    // Breaking execution if no title found on the product template.
    if (!this.$title) {
      return
    }
    this.$info = document.querySelector('.Product-info')

    // Binding context to event listeners.
    this.onLoad = this.adjustDOM.bind(this)
    this.onResizeHandler = throttle(this.adjustDOM.bind(this), 100)

    // Kicking off component.
    this.init()
  }

  /**
   * init - adds Event listeners
   *
   * @memberof ProductIndex
   */
  init () {
    window.addEventListener('load', this.onLoad, false)
    window.addEventListener('resize', this.onResizeHandler, false)
    this.addObserverToProductRow()
  }

  /**
   * destroy - removes event listeners
   *
   * @memberof ProductIndex
   */
  destroy () {
    window.removeEventListener('load', this.onLoad, false)
    window.removeEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * addObserverToProductRow - Adds MutationObserver
   * to .Product-row and calls adjustDOM on mutation to recalculate
   * product row height.
   *
   * @memberof ProductIndex
   */
  addObserverToProductRow () {
    // Get Product Row
    let element = document.querySelector('.Product-row')

    // Config for watching
    let config = { childList: true, attributes: true, characterData: true, subtree: true }

    // Action to apply on observer event
    const callback = (mutationList, observer, that) => {
      this.adjustDOM()
    }

    // Create observer and attach
    const observer = new MutationObserver(callback, this)
    observer.observe(element, config)
  }

  /**
   * adjustDOM - calls for the calculation of new
   * DOM measurements or resets the ones applied
   * depending on the viewport
   *
   * @memberof ProductIndex
   */
  adjustDOM () {
    if (!smallerThan('sm')) {
      this.setElementsHeight()
    } else {
      this.resetAppliedStyles()
    }
  }

  /**
   * setElementsHeight - sets the heights
   * for the Product-info and Product-title elements
   *
   * @memberof ProductIndex
   */
  setElementsHeight () {
    const titleChildrenHeight = this.titleChildrenHeights()
    const productInfoHeight = this.$info.offsetHeight

    this.setInfoTop(titleChildrenHeight)

    const innerRowHeight = titleChildrenHeight + productInfoHeight
    this.setTitleHeight(innerRowHeight)
  }

  /**
   * titleChildrenHeights - calculates height
   * of the composing elements of Product-title
   * including padding and margin in order to offset Product-info
   *
   * @memberof ProductIndex
   */
  titleChildrenHeights () {
    let height = 0

    for (let i = 0; i < this.$title.children.length; i += 1) {
      const element = this.$title.children[i]
      const style = window.getComputedStyle(element)
      const marginBottom = parseInt(style.marginBottom, 10) || 0
      const marginTop = parseInt(style.marginTop, 10) || 0
      const paddingBottom = parseInt(style.paddingBottom, 10) || 0
      const paddingTop = parseInt(style.paddingTop, 10) || 0
      const elementHeight = parseInt(style.height, 10) || 0

      height += elementHeight + marginTop + marginBottom + paddingTop + paddingBottom
    }

    return height
  }

  /**
   * setInfoTop - sets the top style for Product-info
   *
   * @param  {integer} height
   * @memberof ProductIndex
   */
  setInfoTop (height) {
    this.$info.style.top = `${height}px`
  }

  /**
   * setTitleHeight - sets the height style for Product-title
   *
   * @param  {integer} height
   * @memberof ProductIndex
   */
  setTitleHeight (height) {
    this.$title.style.height = `${height}px`
  }

  /**
   * resetAppliedStyles - resets all the styles applied
   *
   * @memberof ProductIndex
   */
  resetAppliedStyles () {
    this.$info.style.top = ''
    this.$title.style.height = ''
  }
}
