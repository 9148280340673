export default class SkipToContent {
  /**
   * Creates an instance of SkipToContent.
   *
   * @memberof SkipToContent
   */
  constructor () {
    this.$element = null

    // Binding the context to event methods.
    this.onTapPress = this.onTapPress.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Initialises the class just by attaching a keyboard event.
   *
   * @memberof SkipToContent
   */
  init () {
    window.addEventListener('hashchange', this.onTapPress, false)
  }

  /**
   * In some browsers webkit focus is not applied correctly
   * on internal (#hash) links. This means that screen readers
   * don't jump to the correct point when the skip navigation link
   * is clicked/selected with the keyboard. We use the following method
   * to fix this. When an internal link is clicked,
   * the target element is given a tabindex of -1.
   * This means the element can receive focus,
   * but this is not part of the normal tab hit for the page.
   * Then the focus is set to the element,
   * and the screen reader can continue from that point.
   *
   *
   * @memberof SkipToContent
   */
  onTapPress () {
    this.$element = document.getElementById(window.location.hash.substring(1))
    if (this.$element) {
      if (!/^(?:a|select|input|button|textarea)$/i.test(this.$element.tagName)) {
        this.$element.tabIndex = -1
      }

      this.$element.focus()
    }
  }

  /**
   * Removes all listeners attached to DOM elements.
   *
   * @memberof SkipToContent
    */
  destroy () {
    window.removeEventListener('hashchange', this.onTapPress, false)
  }
}
