export default class ContentIndex {
  /**
   * Creates an instance of ContentIndex.
   *
   * @memberof ContentIndex
   */
  constructor () {
    this.$contentIndexContainer = document.querySelector('.ContentIndex__container')
    this.$contentIndex = document.querySelector('.ContentIndex')
    this.$contentTitle = document.querySelector('.ContentIndex-title')
    this.$contentList = document.querySelector('.ContentIndex--patternList')
    this.$contentBlock = document.querySelector('.ContentBlock')
    this.$tocFaqItems = document.querySelector('.TableOfContents-includeFaqItems')
    this.$tocHeadings = this.$tocFaqItems ? 'h2:not(.CTA-title)' : 'h2:not(.CTA-title, .Faq-item h2)'
    this.$titles = this.$contentBlock ? this.$contentBlock.querySelectorAll(this.$tocHeadings) : null

    this.isClosedClass = 'is-closed'

    // Kicking off the component.
    this.init()
  }

  /**
   * Initialise the class executing the class methods.
   *
   * @memberof ContentIndex
   */
  init () {
    if (this.$contentList) {
      this.populateList()
    }
  }

  /**
   *
   *
   * @memberof ContentIndex
   */
  onTitleClick () {
    this.$contentIndex.classList.toggle(this.isClosedClass)
  }

  /**
  * Populate the ContentIndex-list with list items properly marked up
  *
  * @static
  * @memberof ContentIndex
  */
  populateList () {
    if (this.$titles && this.$titles.length > 3) {
      this.addLinks()
    } else {
      this.$contentIndexContainer.style.display = 'none'
    }
  }

  /**
   * Add list items to ContentIndex-list.
   *
   * @memberof ContentIndex
   */
  addLinks () {
    if (!this.$titles) {
      return
    }

    this.$titles.forEach(($el, index) => {
      const $current = $el
      const $li = document.createElement('li')
      const title = $current.innerText

      const cleanTitle = title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove accents
      const target = cleanTitle.split(' ').join('_') // replace space with underscore
      const link = `${index + 1}_${target}`.replace(/[^0-9A-Za-z_-]/g, '') // keep only alpha characters and numbers

      if (!$current.id) {
        $current.id = link
      }

      // Adding bullet number to the list item.
      const $span = document.createElement('span')
      $span.setAttribute('class', 'has-text-primary')
      $span.textContent = `${index + 1}.`
      $li.appendChild($span)

      // Adding link to the list item
      const $link = document.createElement('a')
      $link.textContent = $current.textContent
      $link.href = `#${$current.id}`
      $link.setAttribute('title', $current.textContent)
      $link.setAttribute('class', 'has-text-primary')
      $li.appendChild($link)

      // Adding list-item to ContentIndex-list.
      this.$contentList.appendChild($li)
    })
  }
}
