export default class DeliveryMessage {
  /**
   * Creates an instance of DeliveryMessage.
   *
   * @memberof DeliveryMessage
   */
  constructor (deliveryModes) {
    this.$deliveryTimeInfo = document.querySelector('.DeliveryTime-info')
    this.onLoad = this.onLoad.bind(this)
    this.setCutOffMessage = this.setCutOffMessage.bind(this)
    this.deliveryModes = deliveryModes

    this.init()
  }

  /**
   * Initialises the component.
   *
   * @memberof DeliveryMessage
   */
  init () {
    if (!this.$deliveryTimeInfo || !this.deliveryModes) {
      return
    }

    window.addEventListener('load', this.onLoad, false)
  }

  /**
   * Fetches the delivery Info for a product id
   * and sets the relevant cut off message
   *
   * @memberof DeliveryMessage
   */
  onLoad () {
    if (this.deliveryModes.serviceId === '') return

    this.fetchDeliveryOptions(this.deliveryModes.serviceId)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(jsonResponse => {
        const timeToOrder = this.getTimeToOrderMessage(jsonResponse['time_to_order'])
        this.setCutOffMessage(timeToOrder)
      })
      .catch(console.error)
  }

  /**
   * setCutOffMessage
   *
   * sets the remaining delivery time message in the DOM
   *
   * @memberof DeliveryMessage
   */
  setCutOffMessage (timeToOrder) {
    if (!timeToOrder) return

    this.$deliveryTimeInfo.insertAdjacentHTML('beforeend', this.deliveryModes.getDeliveryTimeInfoMessage(timeToOrder))
  }

  /**
   * getTimeToOrderMessage creates a message with the remaining time to order
   *
   * @returns string
   * @memberof DeliveryMessage
   */
  getTimeToOrderMessage (timeToOrder) {
    if (!timeToOrder) return null

    const minMinutesToCompleteAssessment = 5
    let timeToOrderMessage = null

    if (timeToOrder.hours > 0) { // display time as hours if more than an hour left
      if (timeToOrder.minutes >= 30) timeToOrder.hours += 1 // rounds up the amount of hours, if more than Xh and 30min left
      timeToOrderMessage = `${timeToOrder.hours} ${this.deliveryModes.hours}`
    } else if (timeToOrder.minutes > minMinutesToCompleteAssessment) { // display time as minutes if less than an hour left
      timeToOrderMessage = `${timeToOrder.minutes} ${this.deliveryModes.minutes}`
    }

    return timeToOrderMessage
  }

  /**
   * fetchDeliveryMessage - given a serviceId
   * performs an external call that returns its delivery options
   *
   * @param {string} serviceId
   * @return {object}
   * @memberof DeliveryMessage
   */
  fetchDeliveryOptions (serviceId) {
    const deliveryInfoUrl = this.deliveryModes.apiUrl + serviceId

    return fetch(deliveryInfoUrl)
  }

  /**
   * destroy - removes event listeners
   *
   * @memberof DeliveryMessage
   */
  destroy () {
    window.removeEventListener('load', this.onLoad, false)
  }
}
