import browserUpdate from 'browser-update'

export default class BrowserUpdate {
  /**
   * Creates an instance of BrowserUpdate.
   *
   * @memberof BrowserUpdate
   */
  constructor () {
    this.$browserUpdate = document.querySelector('.BrowserUpdate')

    // Save references to header elements for later manipulation
    this.$header = document.querySelector('.Header')
    this.$headerSpaceAllocation = document.querySelector('.Header-spaceAllocation')

    // Attaching context to event handlers.
    this.onResize = this.onResize.bind(this)
    this.onNotificationIgnore = this.onNotificationIgnore.bind(this)
    this.onNotificationPermanentIgnore = this.onNotificationPermanentIgnore.bind(this)
    this.onNotificationUpdate = this.onNotificationUpdate.bind(this)

    // Bind "browser-update" lib's config to instance and start the lib
    this.browserUpdateConfig = {
      required: {
        i: 11, // Internet Explorer
        s: 10, // Safari
        ios: 10, // iOS
        o: 12, // Opera
        o_a: 12, // Opera Android (Maybe not mini)
        a: 4, // Android Browser
        samsung: 4 // Samsung Internet
        /*
        x: -1     // Other
        c: -1,    // Chrome
        silk: -1, // Silk
        e: -1,    // Edge
        f: -1,    // Firefox
        y: -1,    // Yandex Browser
        v: -1,    // Vivaldi
        uc: -1,   // UC Browser
        */
      },
      reminder: 0, // Hours to hide notification if page is reloaded while notification is open
      reminderClosed: 7 * 24, // Hours the notification stays hidden after selecting "ignore"
      newwindow: true, // Open link in new window/tab
      container: document.querySelector('.js-BrowserUpdate'),
      insecure: true, // Notify users with insecure but not out-of-date browsers
      shift_page_down: false, // Add margin-top to body
      text: `
        <span class="BrowserUpdate-message">${window.updateMessage}</span>
        <div class="buorg-buttons">
          <a id="buorgig" class="js-BrowserUpdate-ignoreButton u-linkBlue">
            ${window.ignoreButtonText}
          </a>
          <a id="buorgul" class="js-BrowserUpdate-updateButton u-linkBlue" title="Browser-Update" href="https://browser-update.org/update.html" target="_blank" rel="noopener noreferrer">
            ${window.updateLinkText}
          </a>
        </div>
      `,
      onclose: function (state) { // On "Ignore" click
        setTimeout(() => {
          state.div.style.display = 'block'
          state.div.innerHTML = `
            <div class="buorg-pad">
              <span class="BrowserUpdate-message">${window.ignoreMessage}</span>
              <div class="buorg-buttons">
                <a id="buorgpermanent" class="js-BrowserUpdate-ignorePermanentlyButton u-linkBlue">
                  ${window.ignorePermanentlyButtonText}
                </a>
              </div>
            </div>
          `
        }, 1)
      }
      // test: true // Force notification to open
    }
    browserUpdate(this.browserUpdateConfig)

    // Kicking off the component.
    this.init()
  }

  /**
   * Attaches events to initial DOM elements.
   *
   * @memberof BrowserUpdate
   */
  init () {
    if (!this.isVisible()) return
    this.onResize()

    this.$browserUpdateIgnoreButton = document.querySelector('.js-BrowserUpdate-ignoreButton')
    this.$browserUpdateIgnoreButton.addEventListener('click', this.onNotificationIgnore, false)

    this.$browserUpdateUpdateButton = document.querySelector('.js-BrowserUpdate-updateButton')
    this.$browserUpdateUpdateButton.addEventListener('click', this.onNotificationUpdate, false)

    // Trigger resize
    window.addEventListener('resize', this.onResize, false)
  }

  /**
   * Adjusts header and headerSpaceAllocator DOM elements allowing browser-update notification to live on top
   *
   * @memberof BrowserUpdate
   */
  onResize (event) {
    if (!this.isVisible()) return

    const notificationHeight = this.$browserUpdate.offsetHeight
    const headerHeight = this.$header.offsetHeight

    // Offset header by height of browser update notification
    this.$header.style.marginTop = `${notificationHeight}px`
    // Make height of header space allocator equal to height of browser update notification + header
    this.$headerSpaceAllocation.style.height = `${notificationHeight + headerHeight}px`
  }

  /**
   * Reacts to click on "Ignore" button.
   *
   * @memberof BrowserUpdate
   */
  onNotificationIgnore () {
    // Add event listener to "Never show again"
    setTimeout(() => {
      this.$browserUpdateIgnorePermanentlyButton = document.querySelector('.js-BrowserUpdate-ignorePermanentlyButton')
      this.$browserUpdateIgnorePermanentlyButton.addEventListener('click', this.onNotificationPermanentIgnore, false)

      this.onResize()
    }, 10)
    setTimeout(this.destroy, 5000)
  }

  /**
   * Reacts to click on "Never show again" button.
   *
   * @memberof BrowserUpdate
   */
  onNotificationPermanentIgnore () {
    // Let "browser-update" lib know not to show up again by setting a cookie
    document.cookie = 'browserupdateorg=pause; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT'
    this.destroy()
  }

  /**
   * Reacts to click on "Update browser" button.
   *
   * @memberof BrowserUpdate
   */
  onNotificationUpdate () {
    this.destroy()
  }

  /**
   * Detaches all events from DOM elements.
   *
   * @memberof BrowserUpdate
   */
  destroy () {
    if (!this.$browserUpdate) return

    // Remove any inline stylle pushing down header element
    this.$browserUpdate.innerHTML = null
    this.$header.style.marginTop = null
    this.$headerSpaceAllocation.style.height = null
    window.removeEventListener('resize', this.onResize, false)

    // Cleanup any eventlisteners on buttons
    this.$browserUpdateIgnoreButton.removeEventListener('click', this.onNotificationIgnore, false)
    this.$browserUpdateIgnorePermanentlyButton.removeEventListener('click', this.onNotificationPermanentIgnore, false)
    this.$browserUpdateUpdateButton.removeEventListener('click', this.onNotificationUpdate, false)
  }

  /**
   * Reacts to click on "Ignore" button.
   *
   * @memberof BrowserUpdate
   * @returns {boolean}
   */
  isVisible () {
    // Check is browser-update lib has injected notification
    if (!this.browserUpdateConfig.div) return false

    // Check if inline display style property is "block"
    if (this.browserUpdateConfig.div.style.display === 'block') return true

    // Check if computed display style property is "block"
    if (window.getComputedStyle(this.browserUpdateConfig.div, null).display === 'block') return true

    return false
  }
}
