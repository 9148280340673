import { isEscape } from 'Helpers/keys'
import { toggleAriaAttribute } from 'Helpers/dom'

export default class IconMenu {
  /**
   * Creates an instance of IconMenu.
   *
   * @memberof IconMenu
   */
  constructor () {
    this.$searchIcon = document.querySelector('.IconMenu-searchIcon')
    this.$iconMenu = document.querySelector('.IconMenu')
    this.$iconList = document.querySelector('.IconMenu-list')
    this.$searchForm = document.querySelector('.SearchForm')
    this.$searchInput = document.querySelector('.SearchForm-input')
    this.$closeButton = document.querySelector('.SearchForm-close')
    this.$mainMenu = document.querySelector('.MainMenu')

    // Binding the context to event methods.
    this.onIconClick = this.onIconClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Sets up the event listners.
   *
   * @memberof IconMenu
   */
  init () {
    document.onkeydown = this.onKeyDown
    // this elements might not present due to PPC variations
    if (this.$searchIcon) {
      this.$searchIcon.addEventListener('click', this.onIconClick, false)
    }
    if (this.$closeButton) {
      this.$closeButton.addEventListener('click', this.onCloseClick, false)
    }
  }

  /**
   * Removes all event listeners.
   *
   * @memberof IconMenu
   */
  destroy () {
    document.onkeydown = null
    this.$searchIcon.removeEventListener('click', this.onIconClick, false)
    this.$closeButton.removeEventListener('click', this.onCloseClick, false)
  }

  /**
   * Adding the right classes to the right elements
   * in order to animate the search icon.
   *
   * @param {Event} e
   * @memberof IconMenu
   */
  onIconClick (e) {
    this.$iconMenu.classList.add('is-searchExpanded')
    this.$mainMenu.classList.add('is-searchExpanded')
    toggleAriaAttribute(this.$searchForm, 'aria-hidden')

    // Emptying the input value to start fresh.
    this.$searchInput.value = ''

    // Making the input focused.
    this.$searchInput.click()

    // Applying a class with a bit of delay to avoid the browser
    // changing the child's width before the parents. Otherwise
    // the animation will not work since it won't detect a change
    // on the width.
    window.setTimeout(() => {
      this.$iconList.classList.add('is-searchVisible')
      this.$searchInput.focus()
    }, 50)

    e.preventDefault()
  }

  /**
   * Closes the search form by removing the
   * previously added classes.
   *
   * @param {Event} e
   * @memberof IconMenu
   */
  onCloseClick (e) {
    this.$iconMenu.classList.remove('is-searchExpanded')
    this.$iconList.classList.remove('is-searchVisible')
    this.$mainMenu.classList.remove('is-searchExpanded')
    toggleAriaAttribute(this.$searchForm, 'aria-hidden')

    e.preventDefault()
  }

  /**
   * Closes the search bar if the user pressed ESC.
   *
   * @param {Event} e
   * @memberof IconMenu
   */
  onKeyDown (e) {
    if (isEscape(e)) {
      this.onCloseClick(e)
    }
  }
}
