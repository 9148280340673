/**
 * Smooth scrolls to the given element Id.
 *
 * @param {string} elementId
 */
export default function smoothScroll ($target, customOffset = 0) {
  if (!$target) {
    return
  }

  const $header = document.querySelector('.Header')

  // Calculate header height if there is a header for exact scrolling.
  const headerHeight = $header ? $header.offsetHeight : 0
  let offset = 0
  let $current = $target

  // Calculate the offset from the top of the page
  while ($current) {
    offset += $current.offsetTop
    $current = $current.offsetParent
  }

  window.scroll({
    top: offset + customOffset - headerHeight,
    behavior: 'smooth'
  })
}
