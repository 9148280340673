export default class TrustpilotReviews {
  /**
   * Creates an instance of TrustpilotReviews.
   *
   * @memberof TrustpilotReviews
   */
  constructor () {
    this.$button = document.querySelector('.TrustpilotReviews-button')

    // Attaching context to event handlers.
    this.onButtonClick = this.onButtonClick.bind(this)

    // Kicking off component.
    this.init()
  }

  /**
   * Attaches events to elements.
   *
   * @memberof TrustpilotReviews
   */
  init () {
    if (this.$button) {
      this.$button.addEventListener('click', this.onButtonClick, false)
    }
  }

  /**
   * Removes any attached events.
   *
   * @memberof TrustpilotReviews
   */
  destroy () {
    if (this.$button) {
      this.$button.removeEventListener('click', this.onButtonClick, false)
    }
  }

  /**
   * Displays the next 5 hidden elements when the button is clicked.
   * If all the elements shown, it will remove the button and its
   * event listeners.
   *
   * @memberof TrustpilotReviews
   */
  onButtonClick () {
    const $hiddenReviews = [].slice.call(document.querySelectorAll('.TrustpilotReviews-hidden[aria-hidden="true"]'))
    const $firstReviews = $hiddenReviews.slice(0, 5)

    $firstReviews.forEach(($review) => {
      $review.setAttribute('aria-hidden', 'false')
      $review.removeAttribute('hidden')
    })

    if ($hiddenReviews.length <= 5) {
      document.querySelector('.TrustpilotReviews-show').remove()
      this.destroy()
    }
  }
}
