import { wrapHandler, handleError } from '../../../assets/js/helpers'

export default class RegulationAboveFold {
  /**
   * Creates an instance of RegulationAboveFold.
   *
   * @memberof RegulationAboveFold
   */
  constructor (lazyLoad) {
    this.$desktopClass = 'RegulationAboveFold--desktop'
    this.$mobileClass = 'RegulationAboveFold--mobile'
    this.$contentExpandedClass = 'RegulationAboveFold-contentWrapper--expanded'
    this.$contentClassSelector = '.RegulationAboveFold-contentWrapper'
    this.lazyLoad = lazyLoad
    this.$RegulationAboveFoldDropDown = document.querySelector('.RegulationAboveFold')
    this.$productInfoNormal = document.querySelector('.Product-info')
    this.$productInfoSimplified = document.querySelector('.ProductIndexSimplified-info')

    // Breaking execution if no '.RegulationAboveFold' present on page
    if (!this.$RegulationAboveFoldDropDown) {
      return
    }

    try {
      this.setupDesktopDropDown()
      this.setupMobileDropDown()

      // Wrapping handlers for catching exceptions to report to NR
      this.toggleDesktopDropDown = wrapHandler(this.toggleDesktopDropDown.bind(this))
      this.toggleMobileDropDown = wrapHandler(this.toggleMobileDropDown.bind(this))
      this.toggleSimplifiedProductDropDown = wrapHandler(this.toggleSimplifiedProductDropDown.bind(this))
      this.processImages()

      this.init()
    } catch (e) {
      handleError(e)
    }
  }

  /**
   * Attaches event.
   *
   * @memberof RegulationAboveFold
   */
  init () {
    if (this.$productInfoNormal) {
      this.$desktopDropDownTrigger.addEventListener('click', this.toggleDesktopDropDown, false)
    } else {
      this.$desktopDropDownTrigger.addEventListener('click', this.toggleSimplifiedProductDropDown, false)
    }
    this.$mobileDropDownTrigger.addEventListener('click', this.toggleMobileDropDown, false)
  }

  /**
   * Removes all listeners that could be attached to DOM elements.
   *
   * @memberof RegulationAboveFold
   */
  destroy () {
    this.$desktopDropDownTrigger.removeEventListener('click', this.toggleDesktopDropDown, false)
    this.$desktopDropDownTrigger.removeEventListener('click', this.toggleSimplifiedProductDropDown, false)
    this.$mobileDropDownTrigger.removeEventListener('click', this.toggleMobileDropDown, false)
  }

  /**
   * Setup Desktop Dropdown elements
   *
   * @memberof RegulationAboveFold
   */
  setupDesktopDropDown () {
    this.$desktopDropDownTrigger = document.querySelector(`.${this.$desktopClass} button`)
    this.$desktopDropDownTarget = document.querySelector(`.${this.$desktopClass} ${this.$contentClassSelector}`)
    this.$productContainer = document.querySelector('.Container.Container--gutter30.Product-wrapContainer')
    this.$productWrap = document.querySelector('.Product-wrap')
    this.$productInfo = this.$productInfoNormal ? this.$productInfoNormal : this.$productInfoSimplified
  }

  /**
   * Setup Mobile Dropdown elements
   *
   * @memberof RegulationAboveFold
   */
  setupMobileDropDown () {
    this.$mobileDropDownTrigger = document.querySelector(`.${this.$mobileClass}  button`)
    this.$mobileDropDownTarget = document.querySelector(`.${this.$mobileClass} ${this.$contentClassSelector}`)
  }

  /**
   * Lazy loads both desktop and mobile version's images
   *
   * @memberof RegulationAboveFold
   */
  processImages () {
    this.$mobileDropDownImages = document.querySelectorAll('.RegulationAboveFold img')
    this.$mobileDropDownImages.forEach((image) => {
      this.lazyLoad.load(image)
    })
  }

  /**
   * Toggles Desktop Dropdown and resizes the product wrapper because of absolute positioning
   *
   * @memberof RegulationAboveFold
   */
  toggleDesktopDropDown () {
    if (this.$desktopDropDownTarget.classList.contains(this.$contentExpandedClass)) {
      this.collapseDesktopDropDown()
    } else {
      this.expandDesktopDropDown()
    }
    this.setProductInfoPosition()
    this.adjustProductContainer()
  }

  /**
   * Toggles Mobile Dropdown
   *
   * @memberof RegulationAboveFold
   */
  toggleMobileDropDown () {
    this.$mobileDropDownTarget.classList.toggle(this.$contentExpandedClass)
    if (this.$mobileDropDownTarget.classList.contains(this.$contentExpandedClass)) {
      this.$mobileDropDownTrigger.setAttribute('aria-expanded', 'true')
    } else {
      this.$mobileDropDownTrigger.setAttribute('aria-expanded', 'false')
    }
  }

  /**
   * Toggles Dropdown for IE and FR pages locales that use simplified product index
   *
   * @memberof RegulationAboveFold
   */
  toggleSimplifiedProductDropDown () {
    this.$desktopDropDownTarget.classList.toggle(this.$contentExpandedClass)
  }

  /**
   * Sets the height of the previously absolute positioned Product info when expanding
   * or collapsing in order to avoid images or text overflowing over the container
   *
   * @memberof RegulationAboveFold
   */
  setProductInfoPosition () {
    this.$productInfo.style.top = this.$dropDownProductInfoHeight + 'px'
  }

  /**
   * Sets the height of the previously absolute positioned Product info when expanding
   *
   * @memberof RegulationAboveFold
   */
  adjustProductContainer () {
    this.$productContainer.style.minHeight = this.$productContainerHeight + 'px'
    this.$productWrap.style.minHeight = `${this.$productWrapHeight}px`
  }

  /**
   * Collapses desktop dropdown
   *
   * @memberof RegulationAboveFold
   */
  collapseDesktopDropDown () {
    this.$desktopDropDownTrigger.setAttribute('aria-expanded', 'false')
    this.$productInfoHeight = this.$productInfo.style.top
    this.$dropDownDivHeight = document.querySelector(`.${this.$desktopClass} ${this.$contentClassSelector}`).offsetHeight
    this.$desktopDropDownTarget.classList.remove(this.$contentExpandedClass)
    this.$dropDownProductInfoHeight = parseInt(this.$productInfoHeight) - this.$dropDownDivHeight
    this.$productContainerHeight = this.$productContainer.offsetHeight - this.$dropDownDivHeight
    this.$productWrapHeight = this.$productWrap.offsetHeight - this.$dropDownDivHeight
  }

  /**
   * Expands desktop dropdown
   *
   * @memberof RegulationAboveFold
   */
  expandDesktopDropDown () {
    this.$desktopDropDownTrigger.setAttribute('aria-expanded', 'true')
    this.$productInfoHeight = this.$productInfo.style.top
    this.$desktopDropDownTarget.classList.add(this.$contentExpandedClass)
    this.$dropDownDivHeight = document.querySelector(`.${this.$desktopClass} ${this.$contentClassSelector}`).offsetHeight
    this.$dropDownProductInfoHeight = parseInt(this.$productInfoHeight) + this.$dropDownDivHeight
    this.$productContainerHeight = this.$productContainer.offsetHeight + this.$dropDownDivHeight
    this.$productWrapHeight = this.$productWrap.offsetHeight + this.$dropDownDivHeight
  }
}
