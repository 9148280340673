export default class ProductsGrid {
  /**
   * Creates an instance of TrustpilotReviews.
   *
   * @memberof ProductsGrid
   */
  constructor (lazyLoad) {
    this.$container = document.querySelector('.ProductsGrid')

    if (!this.$container) {
      return
    }

    this.lazyLoad = lazyLoad
    this.$prescriptionFeeCopy = this.$container.querySelector('.ProductsGrid-prescriptionFee')
    this.$button = this.$container.querySelector('.ProductsGrid-moreButton')

    // Attaching context to event listeners.
    this.onShowAll = this.onShowAll.bind(this)
    this.onShowPrescriptionFee = this.onShowPrescriptionFee.bind(this)
    this.onHidePrescriptionFee = this.onHidePrescriptionFee.bind(this)

    // Kicking off component.
    this.init()
  }

  /**
   * Attaches events to elements.
   *
   * @memberof ProductsGrid
   */
  init () {
    this.$container.addEventListener('showall', this.onShowAll, false)
    this.$container.addEventListener('ProductsGrid:showPrescriptionFee', this.onShowPrescriptionFee, false)
    this.$container.addEventListener('ProductsGrid:hidePrescriptionFee', this.onHidePrescriptionFee, false)
    this.$button.addEventListener('click', this.onShowAll, false)
  }

  /**
   * Removes any attached events.
   *
   * @memberof ProductsGrid
   */
  destroy () {
    this.$container.removeEventListener('showall', this.onShowAll, false)
    this.$container.removeEventListener('ProductsGrid:showPrescriptionFee', this.onShowPrescriptionFee, false)
    this.$container.removeEventListener('ProductsGrid:hidePrescriptionFee', this.onHidePrescriptionFee, false)
    this.$button.removeEventListener('click', this.onShowAll, false)
  }

  /**
   * Displays the next hidden products when the button is clicked.
   *
   * @memberof ProductsGrid
   */
  onShowAll () {
    this.$container.classList.add('ProductsGrid-showAll')

    // Displaying any images after clicking the button.
    this.lazyLoad.update()
  }

  /**
   * Shows the prescription price dom node.
   *
   * @memberof ProductsGrid
   */
  onShowPrescriptionFee () {
    if (this.$prescriptionFeeCopy) {
      this.$prescriptionFeeCopy.classList.remove('u-hidden')
    }
  }

  /**
   * Hides the prescription price dom node.
   *
   * @memberof ProductsGrid
   */
  onHidePrescriptionFee () {
    if (this.$prescriptionFeeCopy) {
      this.$prescriptionFeeCopy.classList.add('u-hidden')
    }
  }
}
