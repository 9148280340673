import { smallerThan } from 'Helpers'
import { toggleAriaAttribute } from 'Helpers/dom'
import PrimaryNavigation from './primary-navigation/primary-navigation'
import InfoOptionBox from './info-option-box/info-option-box'

export default class Header {
  /**
   * Creates an instance of Header.
   *
   * @memberof Header
   */
  constructor () {
    this.$mainMenu = document.querySelector('.MainMenu')
    this.$hamburger = document.querySelector('.Hamburger')
    this.$login = document.querySelector('.Login')
    this.$loginButton = document.querySelector('.IconMenu-loginIcon')

    this.PrimaryNavigationInstance = new PrimaryNavigation()
    this.InfoOptionBoxInstance = new InfoOptionBox()

    // Binding the context to event methods.
    this.onHamburgerClick = this.onHamburgerClick.bind(this)
    this.onOverlayClick = this.onOverlayClick.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Attaches all wanted events.
   *
   * @memberof Header
   */
  init () {
    if (this.$hamburger) {
      this.$hamburger.addEventListener('click', this.onHamburgerClick, false)
    }

    if (this.$mainMenu) {
      this.$mainMenu.addEventListener('click', this.onOverlayClick, false)
      this.$mainMenu.addEventListener('click', this.onLinkClick, false)
    }

    window.addEventListener('resize', this.onResize, false)
  }

  /**
   * Removes all listeners attached to DOM elements.
   *
   * @memberof Header
   */
  destroy () {
    this.PrimaryNavigationInstance.destroy()

    if (this.$hamburger) {
      this.$hamburger.removeEventListener('click', this.onHamburgerClick, false)
    }

    if (this.$mainMenu) {
      this.$mainMenu.removeEventListener('click', this.onOverlayClick, false)
      this.$mainMenu.removeEventListener('click', this.onLinkClick, false)
    }

    window.removeEventListener('resize', this.onResize, false)
  }

  /**
   * Open the primary navigation menu on mobile
   * while it triggers the animation on the hamburger
   * icon by adding the `is-active` class.
   *
   * @memberof Header
   */
  onHamburgerClick () {
    this.$hamburger.classList.toggle('is-active')
    this.$mainMenu.classList.toggle('is-collapsed')

    // Toggling the aria-expanded attribute for accessibility.
    toggleAriaAttribute(this.$hamburger, 'aria-expanded')

    // Calculate where the initial position of the InfoOption box.
    this.InfoOptionBoxInstance.positionBox()
  }

  /**
   * Detecting clicks on the overlay pseudoelement then closing the menu.
   *
   * @param {any} e
   * @returns
   * @memberof Header
   */
  onOverlayClick (e) {
    // We can only click on the overlay on tablet.
    if (smallerThan('sm') || !smallerThan('lg')) {
      return
    }

    const $primary = this.$mainMenu.querySelector('.MainMenu-primary')
    const isOpened = this.$mainMenu.className.indexOf('is-collapsed') > -1

    // We cannot detect clicks on a pseudoelement so
    // we are just detecting clicks on the MainMenu element.
    if (!$primary.contains(e.target) && isOpened) {
      this.onHamburgerClick(e)
    }
  }

  /**
   * Calculates the position of the InfoOption box on resize.
   *
   * @returns
   * @memberof Header
   */
  onResize () {
    if (smallerThan('lg')) {
      this.InfoOptionBoxInstance.positionBox()
    }
  }

  /**
   * If Clicked link href contains a scroll link,
   * then toggle collapse menu.
   *
   * @returns
   * @memberof Header
   */
  onLinkClick (e) {
    if (e.target.href && e.target.href !== '') {
      const isOpened = this.$mainMenu.className.indexOf('is-collapsed') > -1

      if (isOpened && e.target.href.indexOf('#') > -1) {
        this.onHamburgerClick()
      }
    }
  }
}
