import Cookies from 'js-cookie'

/**
 *
 * @export
 * @class SegmentProvider
 */
export default class SegmentProvider {
  constructor (cookies) {
    this.cookies = cookies || Cookies
  }

  /**
   * Checks whether the analytics is present
   * NOTE: while window.analytics loads itself up, there is a mock object that
   * has an event queue that holds events created and fires them once the library
   * is fully loaded
   *
   * @static
   * @returns
   * @memberof SegmentProvider
   */
  static isSetup () {
    return !!window.analytics
  }

  /**
   * Tracks an event if and only if:
   *
   * - The provider client is set up.
   * - There is an event name defined.
   *
   * @static
   * @param {*} eventName
   * @param {*} [eventData={}]
   * @memberof SegmentProvider
   */
  static trackEvent (eventName, eventData = {}) {
    if (SegmentProvider.isSetup() && eventName) {
      window.analytics.track(eventName, eventData)
    }
  }

  static pageViewEvent (eventData) {
    if (SegmentProvider.isSetup()) {
      window.analytics.page(eventData)
    }
  }

  /**
   * Update traits for a user
   *
   * @param user
   * @param traits
   */
  static updateUserTraits (user, traits) {
    window.analytics.identify(user, traits)
  }

  /**
   * Add Segment Hotjar Middleware
   */

  hotjarMiddleWare ({ payload, integration, next }) {
    // Remove PII data from the payload sent to Hotjar
    delete payload.obj.traits.email
    delete payload.obj.traits.firstName
    delete payload.obj.traits.gender
    delete payload.obj.traits.lastName

    next(payload)
  }

  /**
   * Gets promise for fully loaded analytics
   *
   * @returns {Promise} promise for analytics
   * @memberof Tracking
   */
  getAnalyticsReadyPromise () {
    return this.getAnalyticsSnippetPromise()
      .then(() => {
        return this.getAnalyticsLibraryPromise()
      })
  }

  /**
   * Gets promise for loaded analytics snippet
   *
   * @returns {Promise} promise for analytics snippet
   * @memberof Tracking
   */
  getAnalyticsSnippetPromise () {
    return new Promise((resolve, reject) => {
      function handleSnippetReady () {
        document.body.removeEventListener('analytics-snippet-ready', handleSnippetReady, true)
        resolve()
      }

      if (window.location.search.includes('disableConsentManager')) {
        reject(new Error('Consent manager is disabled'))
      }

      if (window.analytics) {
        resolve()
      } else {
        document.body.addEventListener('analytics-snippet-ready', handleSnippetReady, true)
      }
    })
  }

  /**
   * Gets promise for loaded analytics library
   *
   * @returns {Promise} promise for analytics library
   * @memberof Tracking
   */
  getAnalyticsLibraryPromise () {
    return new Promise((resolve, reject) => {
      function handleLoadError () {
        document.body.removeEventListener('analytics-load-error', handleLoadError, true)
        reject(new Error('error loading analytics.js'))
      }

      window.analytics.ready(() => {
        window.analytics.addDestinationMiddleware('Hotjar', [ this.hotjarMiddleWare ])

        document.body.removeEventListener('analytics-load-error', handleLoadError, true)
        resolve()
      })

      document.body.addEventListener('analytics-load-error', handleLoadError, true)
    })
  }

  /**
   * Checks if tracking is disabled by user preference
   *
   * @returns {boolean} if tracking is disabled
   * @memberof Tracking
   */
  isTrackingDisabled () {
    const trackingPreferences = this.cookies.getJSON('tracking-preferences')

    if (trackingPreferences) {
      // Checking if any destination is enabled
      if (!Object.values(trackingPreferences.destinations).filter(value => value).length) {
        return true
      }
    }

    return false
  }

  /**
   * Get `tracking-preference` cookie
   * @return boolean
   */
  getTrackingPreferences () {
    return this.cookies.getJSON('tracking-preferences')
  }

  /**
   * Determines if custom tracking have been enabled by the user
   * @return boolean
   */
  isCustomTrackingEnabled (type) {
    let cookieValue = this.getTrackingPreferences()
    if (cookieValue) {
      return cookieValue.custom[type]
    }

    return false
  }

  /**
   * Determines if functional cookies have been enabled by the user
   * @return boolean
   */
  isUserLoggedIn () {
    return SegmentProvider.isSetup() && window.analytics.user().id() !== null
  }
}
