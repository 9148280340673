/**
 * Checks whether the given event has been fired
 * as a result of pressing the given keys.
 *
 * @export
 * @param {Event} e
 * @param {Number} code
 * @param {String|Array} names
 * @returns {Boolean}
 */
export function isPressedKey (e, code, names) {
  const event = e || window.event
  let isKey = false

  if ('key' in event) {
    if (typeof names === 'string') {
      isKey = (event.key === names)
    } else if (Object.prototype.toString.call(names) === '[object Array]') {
      for (let i = 0, length = names.length; i < length; i += 1) {
        if (event.key === names[i]) {
          isKey = true
          break
        }
      }
    }
  } else {
    isKey = (event.keyCode === code)
  }

  return isKey
}

/**
 * Returns true if pressed key is Escape.
 *
 * @export
 * @param {Event} e
 * @returns {Boolean}
 */
export function isEscape (e) {
  return isPressedKey(e, 27, ['Escape', 'Esc'])
}

/**
 * Returns true if pressed key is Enter.
 *
 * @export
 * @param {Event} e
 * @returns {Boolean}
 */
export function isEnter (e) {
  return isPressedKey(e, 13, 'Enter')
}

/**
 * Returns true if pressed key is Tab.
 *
 * @export
 * @param {Event} e
 * @returns {Boolean}
 */
export function isTab (e) {
  return isPressedKey(e, 9, ['tab', 'Tab'])
}

/**
 * Forcing user keyboard to close on mobile.
 *
 * @param {string} element
 */
export function hideKeyboard (element) {
  // Force keyboard to hide on input field.
  element.readOnly = 'readonly'

  // Force keyboard to hide on textarea field.
  element.disabled = 'true'

  window.setTimeout(() => {
    // Actually close the keyboard
    element.blur()

    // Removing attributes after keyboard is hidden.
    element.removeAttribute('readOnly')
    element.removeAttribute('disabled')
  }, 100)
}
