/**
 * The class is used as a settings container for the locale specific settings and
 * the service ID - coming from PHP. It takes them from a "data-deliveryModes"
 * attribute on the main script tag.
 */
export default class DeliveryModesSettings {
  /**
   * Creates an instance of DeliveryModesService.
   *
   * @memberof DeliveryModesSettings
   */
  constructor () {
    // this should be the tag in which this script is run or the data should be set on an element with id builder-delivery-mode
    const mainElement = document.getElementById('zava-main-js')
    const deliveryOptionsElement = document.getElementById('builder-delivery-mode')
    const mainElementData = mainElement && mainElement.getAttribute('data-deliveryModes')
    const deliveryOptionsElementData = deliveryOptionsElement && deliveryOptionsElement.getAttribute('data-deliveryModes')

    this.deliveryModesAttribute = mainElementData || deliveryOptionsElementData

    this.init()
  }

  /**
   * Inits the service by parsing and copying all properties from the script
   * tag attribute to the service instance.
   *
   * @memberof DeliveryModesSettings
   */
  init () {
    if (!this.deliveryModesAttribute) {
      return
    }

    let deliveryModesSettings = JSON.parse(this.deliveryModesAttribute)
    Object.assign(this, deliveryModesSettings)

    this.priceFormatter = new Intl.NumberFormat(this.currencyLanguage, {
      style: 'currency',
      currency: this.currencyCode,
      minimumFractionDigits: 2
    })
  }

  /**
   * Returns the delivery time info message with time to order and delivery mode
   * @param  {String} timeToOrder  Eg. 39 Minutes
   * @param  {String} deliveryMode Type of delivery
   * @return {String}              The message
   * @memberof DeliveryModesSettings
   */
  getDeliveryTimeInfoMessage (timeToOrder) {
    return this.deliveryTimeInfo.replace('{timeToOrder}', timeToOrder)
  }
}
