export default class DeliveryOptionsTemplates {
  /**
   * Creates an instance of DeliveryOptionsTemplates.
   *
   * @param {object} opts object containing custom messages and
   * a locale based currency transformator function
   * @memberof DeliveryOptionsTemplates
   */
  constructor (opts) {
    this.renderRows = this.renderRows.bind(this)
    this.renderRow = this.renderRow.bind(this)
    this.price = this.price.bind(this)
    this.slugify = this.slugify.bind(this)

    this.freeDeliveryMessage = opts.freeDeliveryMessage
    this.footerMessage = opts.footerMessage
    this.priceFormatter = opts.priceFormatter
  }

  /**
   * Given a list of delivery Options
   * renders the delivery options table
   *
   * @param {[object]} deliveryOptions
   * @return {string}
   * @memberof DeliveryOptionsTemplates
   */
  renderTable (deliveryOptions) {
    const footerMessage = this.footerMessage
    const deliveryOptionsRows = this.renderRows(deliveryOptions)

    return `<table class="DeliveryOptions-table Table Table--lined" role="grid">
      <tbody>
        ${deliveryOptionsRows}
        <tr class="DeliveryOptions-extendedInfo" tabindex="0">
          <td colspan="2" class="u-textMuted">
            ${footerMessage}
          </td>
       </tr>
      </tbody>
    </table>`
  }

  /**
   * Map reduce of the delivery options into a html table entries
   *
   * @param {[object]} deliveryOptions
   * @return {string}
   * @memberof DeliveryOptionsTemplates
   */
  renderRows (deliveryOptions = []) {
    return deliveryOptions
      .map(deliveryOption => this.renderRow(deliveryOption))
      .reduce((html, row) => html + row, '')
  }

  /**
   * Renders a html table row based on a delivery Option
   *
   * @param {object} deliveryOption
   * @return {string}
   * @memberof DeliveryOptionsTemplates
   */
  renderRow (deliveryOption) {
    if (!deliveryOption) return null

    const name = deliveryOption.name || null
    const nameSlug = `${deliveryOption.id}-${this.slugify(name)}` || null
    const formattedPrice = this.price(deliveryOption.price) || null
    const estimatedDelivery = deliveryOption.estimated_delivery || ''
    const description = deliveryOption.description || ''

    return `<tr class="DeliveryOptions-title" tabindex="0">
      <td>
        <a href="javascript:void(0)" class="DeliveryOptions-link hb-font-link-text" aria-haspopup="true" aria-labelledby="${nameSlug}">
          <strong>${name}</strong>
          <i class="Icon Icon-information-circle"></i>
        </a>
      </td>
      <td class="DeliveryOptions-price u-textRight">
        <strong>${formattedPrice}</strong>
      </td>
    </tr>
    <tr class="DeliveryOptions-estimatedTime" tabindex="-1">
      <td colspan="2" class="u-textMuted">
        ${estimatedDelivery}
      </td>
    </tr>
    <tr class="DeliveryOptions-info" tabindex="-1">
      <td colspan="2" id="${nameSlug}">
        ${description}
      </td>
    </tr>`
  }

  /**
   * Renders a formatted price
   * to be displayed in the delivery options table
   *
   * @param {string} amountInCentsStr
   * @return {string}
   * @memberof DeliveryOptionsTemplates
   */
  price (amountInCentsStr) {
    const amountInCents = parseInt(amountInCentsStr)
    if (amountInCents === 0) {
      return `${this.freeDeliveryMessage}`
    } else {
      return `${this.priceFormatter.format(amountInCents / 100)}`
    }
  }

  /**
   * returns a slugified version of a string
   *
   * @param {string} text
   * @return {string}
   * @memberof DeliveryOptionsTemplates
   */
  slugify (text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}
