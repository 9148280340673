import { toggleAriaAttribute } from 'Helpers/dom'
import { isEscape } from 'Helpers/keys'
import { smallerThan } from 'Helpers'

export default class InfoOptionDesktop {
  /**
   * Creates an instance of InfoOptionDeskTop.
   *
   * @memberof InfoOptionDesktop
   */
  constructor () {
    this.$infoOptionDesktop = document.querySelector('.InfoOptionDesktop')
    this.$infoOptionButton = document.querySelector('.IconMenu-infoIcon')

    // Binding the context to event methods.
    this.onBodyClick = this.onBodyClick.bind(this)
    this.onInfoOptionButtonClick = this.onInfoOptionButtonClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Sets up the event listners.
   *
   * @memberof InfoOptionDesktop
   */
  init () {
    if (this.$infoOptionButton) {
      this.$infoOptionButton.addEventListener('click', this.onInfoOptionButtonClick, false)
    }

    document.body.addEventListener('click', this.onBodyClick, false)
    window.onkeydown = this.onKeyDown
  }

  /**
   * Removes all event listeners.
   *
   * @memberof InfoOptionDesktop
   */
  destroy () {
    if (this.$infoOptionButton) {
      this.$infoOptionButton.removeEventListener('click', this.onInfoOptionButtonClick, false)
    }

    document.body.removeEventListener('click', this.onBodyClick, false)
    window.onkeydown = null
  }

  /**
   * Closing the popup.
   *
   * @memberof InfoOptionDesktop
   */
  closePopup () {
    this.$infoOptionButton.classList.remove('is-active')
    this.$infoOptionDesktop.classList.add('is-collapsed')
  }

  /**
   * Open the info option popover desktop
   *
   * @memberof InfoOptionDesktop
   */
  onInfoOptionButtonClick () {
    this.$infoOptionButton.classList.toggle('is-active')
    this.$infoOptionDesktop.classList.toggle('is-collapsed')

    // Toggling the aria-expanded attribute for accessibility.
    toggleAriaAttribute(this.$infoOptionButton, 'aria-expanded')
    toggleAriaAttribute(this.$infoOptionDesktop, 'aria-hidden')
  }

  /**
  * Handler for clicking the body of the document, eg. to react to
  * clicking outside the info option panel.
  * @memberof InfoOptionDesktop
  */
  onBodyClick (e) {
    if (smallerThan('lg') || !this.$infoOptionDesktop) {
      return
    }

    const isOutside = !this.$infoOptionDesktop.contains(e.target)
    const isNotButton = !this.$infoOptionButton.contains(e.target)

    if (this.$infoOptionDesktop && isOutside && isNotButton) {
      this.closePopup()
    }
  }

  /**
   * Closing popup when pressing Escape.
   *
   * @param {any} e
   * @memberof InfoOptionDesktop
   */
  onKeyDown (e) {
    if (isEscape(e)) {
      this.closePopup()
    }
  }
}
