export const queryAll = document.querySelectorAll.bind(document)
export const query = document.querySelector.bind(document)

export function isElementInViewport (el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const breakpoints = getBreakpoints()

// Update a part of the url
// https://stackoverflow.com/a/11654596
export function updateQueryString (key, value, url) {
  let newUrl
  if (!url) newUrl = window.location.href

  const re = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi')
  let hash

  if (re.test(newUrl)) {
    if (typeof value !== 'undefined' && value !== null) {
      return newUrl.replace(re, `$1${key}=${value}$2$3`)
    }
    hash = newUrl.split('#')
    newUrl = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '')
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
      newUrl += `#${hash[1]}`
    }
    return newUrl
  }

  if (typeof value !== 'undefined' && value !== null) {
    const separator = newUrl.indexOf('?') !== -1 ? '&' : '?'
    hash = newUrl.split('#')
    newUrl = `${hash[0] + separator + key}=${value}`
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
      newUrl += `#${hash[1]}`
    }
    return newUrl
  }

  return newUrl
}

export function isScrolledPast (selector, offset) {
  const element = document.querySelector(selector)
  if (element) {
    const elementDomRect = element.getBoundingClientRect()
    const header = document.querySelector('.Header')
    const headerHeight = header ? header.getBoundingClientRect().height : 0
    return (elementDomRect.bottom - headerHeight - offset) <= 0
  }
  return false
}

// importing variables
function removeQuotes (string) {
  if (typeof string === 'string' || string instanceof String) {
    return string.replace(/^['"]+|\s+|\\|(;\s?})+|['"]$/g, '')
  }

  return null
}

function getBreakpoints () {
  if (window.getComputedStyle && window.getComputedStyle(document.body, '::before')) {
    let style = window.getComputedStyle(document.body, '::before')

    if (style.content !== 'none') {
      return JSON.parse(removeQuotes(style.content))
    }
  }

  return {
    xs: '575px',
    sm: '768px',
    md: '992px',
    lg: '1200px'
  }
}

export function smallerThan (size) {
  return window.innerWidth < parseInt(breakpoints[size], 10)
}

export function slug (str) {
  return str
    .slice(0, 7)
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/[^\w-]+/g, '')
}

export const handleError = function (err, cb) {
  if (!(err instanceof Error)) {
    err = Error(err)
  }
  if (window.newrelic && typeof window.newrelic.noticeError === 'function') {
    window.newrelic.noticeError(err)
  } else {
    console.error(err)
  }
  cb && cb()
}

export const getWidth = function (elem) {
  const styles = window.getComputedStyle(elem)
  const margin = parseFloat(styles['marginLeft']) + parseFloat(styles['marginRight'])
  return Math.ceil(elem.offsetWidth + margin)
}

export const wrapHandler = function (fn, handleErrorfn = handleError) {
  return function (evt) {
    new Promise(resolve => {
      resolve(fn(evt))
    }).catch(e => {
      handleErrorfn(e)
    })
  }
}
