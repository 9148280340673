/**
 * This class is just responsible for forming out the data
 * to be sent to our tracking system.
 *
 * It gets the data mainly from two sources:
 *
 * - window.zavamed.tracking
 * - tracked element attribute data-tracking-data
 *
 * And it will return:
 *
 * - Event name
 * - Event data
 *
 * @class TrackingData
 */
export default class TrackingData {
  /**
   * Returns the event and tracking data composite
   * from the page, element and screen info to be sent
   * to our tracking provider for tracked element.
   *
   * @static
   * @param {HTMLElement} element
   * @returns ({String, object}) eventMetadata
   * @memberof TrackingData
   */
  static get (trackedElement = null) {
    let { trackingEvent = null, trackingData = null } = this.elementMetadata(trackedElement)
    trackingData = this.composeMetadata(trackingData)

    return {
      trackingEvent,
      trackingData
    }
  }

  /**
   * Returns the tracking data composite
   * from the page and screen info to be sent
   * to our tracking provider.
   *
   * @static
   * @returns ({object}) page metadata + screen info
   * @memberof TrackingData
   */
  static composeMetadata (elementData = {}) {
    return Object.assign(elementData, this.pageMetadata(), this.screenInfo())
  }

  /**
   * Returns the event and tracking data to send
   * to our tracking provider for tracked element.
   *
   * @static
   * @param {HTMLElement} element
   * @returns ({String, object}) eventMetadata
   * @memberof TrackingData
   */
  static elementMetadata (element) {
    let { trackingEvent = null, trackingData = null } = element.dataset
    let eventType = this.eventType(element)

    switch (eventType) {
      case 'form':
        trackingData = this.formData(element)
        break
      case 'plain-link':
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment#Assignment_without_declaration
        ({ trackingEvent, trackingData } = this.plainLinkData(element))
        break
      case 'default':
        trackingData = JSON.parse(trackingData)
        break
      default:
        console.error('no suitable element type found')
    }

    return {
      trackingEvent,
      trackingData
    }
  }

  /**
   * Returns the event type of the element
   *
   * @static
   * @param {HTMLElement} element
   * @returns {string|null}
   * @memberof TrackingData
   */
  static eventType (element) {
    if (this.hasDataTrackingEvent(element)) {
      if (this.hasDataTrackingData(element)) {
        return 'default'
      } else {
        return 'form'
      }
    }
    if (this.isPlainLink(element)) {
      return 'plain-link'
    }
    return null
  }

  /**
   * Returns the metadata based on the form properties
   *
   * @static
   * @param {HTMLElement} element
   * @returns {object}
   * @memberof TrackingData
   */
  static formData (element) {
    // If no tracking data defined for the tracked element we need to loop
    // through all elements with name or id to send them.
    // This happens mostly on submitted forms.
    let data = {}
    Array.prototype.slice.call(element).forEach((item) => {
      if (item.dataset.trackingProperty) {
        data[item.dataset.trackingProperty] = item.value
      }
    })
    return data
  }

  static hasDataTrackingEvent (element) {
    return !!element.getAttribute('data-tracking-event')
  }

  static hasDataTrackingData (element) {
    return !!element.getAttribute('data-tracking-data')
  }

  static isPlainLink (element) {
    return element.tagName === 'A' && !!element.getAttribute('href')
  }

  /**
   * Returns tracking data for link
   * without tags
   *
   * @static
   * @param {HTMLElement} element
   * @returns {object}
   * @memberof TrackingData
   */
  static plainLinkData (element) {
    const trackingEvent = 'button tap'
    const trackingData = {
      step: 'link',
      link: element.getAttribute('href'),
      link_text: element.innerText
    }
    return {
      trackingEvent,
      trackingData
    }
  }

  /**
   * Returns the page related tracking data
      *
   * @static
   * @returns {object}
   * @memberof TrackingData
   */
  static pageMetadata () {
    if (window.zavamed && window.zavamed.trackingData) {
      return window.zavamed.trackingData
    }

    return null
  }

  /**
   * Return a request ID from a UTM parameter (for the Conectia pixel/affiliates) if available
   *
   * @static
   * @returns {Number|null}
   */
  static getReqID () {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('reqid')
  }

  /**
   * Returns the browser's screen information
   *
   * @static
   * @returns {object}
   * @memberof TrackingData
   */
  static screenInfo () {
    if (window.screen) {
      return { screen_height: window.screen.height, screen_width: window.screen.width }
    }

    return null
  }
}
