import throttle from 'lodash/throttle'
import { smallerThan } from 'Helpers'

export default class ProductIndexSimplified {
  /**
   * Creates an instance of ProductIndexSimplified
   *
   * @memberof ProductIndexSimplified
   */
  constructor () {
    this.$container = document.querySelector('.ProductIndexSimplified')

    if (!this.$container) {
      return
    }

    this.$info = document.querySelector('.ProductIndexSimplified-info')
    this.$carousel = document.querySelector('.ProductIndexSimplified-imageCarousel')

    // Binding context to event listeners.
    this.onResizeHandler = throttle(this.adjustDOM.bind(this), 100)

    // Kicking off component.
    this.init()
  }

  /**
   * init - adds Event listeners
   *
   * @memberof ProductIndexSimplified
   */
  init () {
    this.adjustDOM()

    window.addEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * destroy - removes event listeners
   *
   * @memberof ProductIndexSimplified
   */
  destroy () {
    window.removeEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * adjustDOM - change the position of Product Carousel
   *
   * @memberof ProductIndexSimplified
   */
  adjustDOM () {
    if (!smallerThan('sm')) {
      this.$container.appendChild(this.$carousel)
    } else {
      this.$info.appendChild(this.$carousel)
    }
  }
}
