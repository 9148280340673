/*!
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
let dataMemory = {}
class MemoryStorage {
  static setItem (key, value) {
    dataMemory[key] = value
    return dataMemory[key]
  }

  static getItem (key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined
  }

  static removeItem (key) {
    return delete dataMemory[key]
  }

  static clear () {
    dataMemory = {}
    return dataMemory
  }
}
class StorageHelper {
  constructor () {
    try {
      this.storageWindow = window.localStorage
      this.storageWindow.setItem('aws.cognito.test-ls', 1)
      this.storageWindow.removeItem('aws.cognito.test-ls')
    } catch (exception) {
      this.storageWindow = MemoryStorage
    }
  }

  getStorage () {
    return this.storageWindow
  }
}

class AuthCheck {
  constructor (clientId) {
    this.clientId = clientId
    this.storage = new StorageHelper().getStorage()
    Object.freeze(this)
  }

  isUserSignedIn () {
    const federatedInfo = JSON.parse(
      this.storage.getItem('aws-amplify-federatedInfo')
    )
    if (federatedInfo) {
      return true
    }
    const lastUserKey = 'CognitoIdentityServiceProvider.' + this.clientId + '.LastAuthUser'
    return this.storage.getItem(lastUserKey) !== null
  }
}

export { AuthCheck as default }
