import throttle from 'lodash/throttle'
import { smallerThan } from 'Helpers'
import { closest } from 'Helpers/dom'

export default class Accordion {
  /**
   * Creates an instance of Accordion.
   *
   * @memberof Accordion
   */
  constructor () {
    this.$accordions = document.querySelectorAll('.Accordion')
    this.$responsiveAccordions = document.querySelectorAll('.Accordion.Accordion--responsive')

    // Bind context to event handlers.
    this.onResizeHandler = throttle(this.onResize.bind(this), 500)

    // Kicking off the component.
    this.init()
  }

  /**
   * Attaches all events to DOM elements.
   *
   * @memberof Accordion
   */
  init () {
    if (!this.$accordions) {
      return
    }

    this.$accordions.forEach(($accordion) => {
      $accordion.addEventListener('click', Accordion.onClick, false)
      $accordion.addEventListener('keydown', Accordion.onKeyDown, false)
      if ($accordion.hasAttribute('data-disable-links') === true) {
        const links = $accordion.querySelectorAll('a[href]')
        links.forEach(link => {
          link.removeAttribute('href')
          link.setAttribute('aria-disabled', 'true')
        })
      }
    })

    window.addEventListener('resize', this.onResizeHandler, false)

    // Trigger resize in order to remove the aria attributes
    // on the first load if applicable (viewport >= 'sm').
    this.onResize()
    this.openFirstAccordion()
  }

  /**
   * Deattaches all events to DOM elements.
   *
   * @memberof Accordion
   */
  destroy () {
    if (!this.$accordions) {
      return
    }

    this.$accordions.forEach(($accordion) => {
      $accordion.removeEventListener('click', Accordion.onClick, false)
      $accordion.removeEventListener('keydown', Accordion.onKeyDown, false)
    })

    window.removeEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * Closes an accordion by its trigger button.
   *
   * @param {any} trigger
   * @memberof Accordion
   */
  static closeAccordion ($trigger) {
    // Close the activated accordion, using aria-controls to specify the desired section.
    document.getElementById($trigger.getAttribute('aria-controls')).setAttribute('hidden', '')
    // Set the expanded state on the triggering element.
    $trigger.setAttribute('aria-expanded', 'false')
  }

  /**
   * Opens an accordion by its trigger button.
   *
   * @param {any} trigger
   * @memberof Accordion
   */
  static openAccordion ($trigger) {
    const $element = document.getElementById($trigger.getAttribute('aria-controls'))

    if ($element) {
      // Open the activated accordion, using aria-controls to specify the desired section.
      $element.removeAttribute('hidden')

      // Set the expanded state on the triggering element.
      $trigger.setAttribute('aria-expanded', 'true')
    }
  }

  /**
   * Reacts to click events on accordions.
   *
   * @param {Event} e
   * @memberof Accordion
   */
  static onClick (event) {
    let target

    if (event.target.classList.contains('Accordion-trigger')) {
      target = event.target
    } else if (event.target.parentNode.classList.contains('Accordion-trigger')) {
      target = event.target.parentNode
    } else {
      return
    }

    const isExpanded = target.getAttribute('aria-expanded') === 'true'

    if (isExpanded) {
      Accordion.closeAccordion(target)
    } else if (!isExpanded) {
      Accordion.openAccordion(target)
    }

    event.preventDefault()
  }

  /**
   * Reacts to keydown events on accordions to help
   * our users to focus on the right accordion after
   * pressing any key.
   *
   * It reacts to the following key press events:
   *
   * 33 = Page Up
   * 34 = Page Down
   * 35 = End
   * 36 = Home
   * 38 = Up
   * 40 = Down
   *
   * This method was extracted and tweaked a bit from
   * https://www.w3.org/TR/wai-aria-practices/examples/accordion/accordion.html
   *
   * @param {Event} e
   * @memberof Accordion
   */
  static onKeyDown (event) {
    const target = event.target
    const $accordion = closest(target, 'Accordion')
    const $triggers = $accordion.querySelectorAll('.Accordion-trigger')
    const $panels = $accordion.querySelectorAll('.Accordion-panel')
    const key = event.which.toString()
    // 33 = Page Up, 34 = Page Down.
    const ctrlModifier = (event.ctrlKey && key.match(/33|34/))

    // Is this coming from an accordion header?
    if (target.classList.contains('Accordion-trigger')) {
      if (key.match(/38|40/) || ctrlModifier) {
        // 38 = Up, 40 = Down, ctrlModifier = Page Up/Page Down.
        const index = Array.from($triggers).indexOf(target)
        const direction = (key.match(/34|40/)) ? 1 : -1
        const length = $triggers.length
        const newIndex = (index + length + direction) % length
        $triggers[newIndex].focus()
      } else if (key === '35') {
        // 35 = End.
        $triggers[$triggers.length - 1].focus()
      } else if (key === '36') {
        // 36 = Home.
        $triggers[0].focus()
      }
    } else if (ctrlModifier) {
      // Control + Page Up/Page Down keyboard operations.
      // Catches events that happen inside of panels.
      $panels.forEach(($panel, index) => {
        if ($panel.contains(target)) {
          $triggers[index].focus()
          event.preventDefault()
        }
      })
    }
  }

  /**
   * Removes the aria attributes on desktop for responsive accordions
   * and puts them back for accordions which do not have them on mobile.
   *
   * @memberof Accordion
   */
  onResize () {
    if (!this.$responsiveAccordions) {
      return
    }

    this.$responsiveAccordions.forEach(($accordion) => {
      $accordion.querySelectorAll('.Accordion-trigger').forEach(($trigger) => {
        if (smallerThan('sm') && !$trigger.hasAttribute('aria-expanded')) {
          // On mobile add aria attributes if element hasn't got them.
          $trigger.setAttribute('aria-expanded', false)
          document.getElementById($trigger.getAttribute('aria-controls')).setAttribute('hidden', '')
        } else if (!smallerThan('sm') && $trigger.hasAttribute('aria-expanded')) {
          // On tablet onwards remove aria attributes if element has got them.
          $trigger.removeAttribute('aria-expanded')

          const $element = document.getElementById($trigger.getAttribute('aria-controls'))

          if ($element) {
            $element.removeAttribute('hidden')
          }
        }
      })
    })
  }

  /**
   * Opens the first accordion in the list if data attribute available
   *
   * @memberof Accordion
   */
  openFirstAccordion () {
    if (this.$accordions.length < 1) {
      return
    }

    this.$accordions.forEach(($accordion) => {
      if ($accordion.hasAttribute('data-open-first-accordion')) {
        const triggers = $accordion.querySelectorAll('.Accordion-trigger')

        // Open the first accordion
        if (triggers.length > 0 && triggers[0].getAttribute('aria-expanded') === 'false') {
          triggers[0].click()
        }
      }
    })
  }
}
