import HTMLElementShim from './ce-shim'

const template = document.createElement('template')
template.innerHTML = '<slot name="before"></slot><slot></slot><slot name="after"></slot>'

class MainNavigation extends HTMLElementShim {
  constructor () {
    super()
    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.appendChild(template.content.cloneNode(true))
  }

  close () {
    this.querySelectorAll('.MainMenuReboot__panel-isOpen').forEach((el) => {
      const origin = el.closest('.MainMenuReboot__item.isActive') || this.querySelector('.Hamburger.isActive')
      if (origin) {
        origin.classList.remove('isActive')
      }
      el.classList.remove('MainMenuReboot__panel-isOpen')
      if (window.handleToggle) {
        window.handleToggle(false, el)
      }
    })
  }

  handleScroll (evt, child) {
    const root = document.documentElement
    let percent = Math.round(evt.target.scrollLeft / (child.scrollWidth - evt.target.offsetWidth) * 100) / 100
    root.style.setProperty('--menu-fade-left', percent.toString())
    root.style.setProperty('--menu-fade-right', (1 - percent).toString())

    if (percent < 0.95) {
      this.classList.add('right-fade')
    } else {
      this.classList.remove('right-fade')
    }

    if (percent > 0.05) {
      this.classList.add('left-fade')
    } else {
      this.classList.remove('left-fade')
    }
  }

  openMenu (evt, item) {
    evt.stopPropagation()

    const isBurger = item.classList.contains('Hamburger')
    const childPanel = isBurger ? item.parentNode.querySelector('.MainMenuReboot__panel') : item.querySelector('.MainMenuReboot__panel')
    const wasOpen = childPanel ? childPanel.classList.contains('MainMenuReboot__panel-isOpen') : false

    if (isBurger) {
      evt.stopImmediatePropagation()
    }

    // remove all active states from current level
    const parentPanel = isBurger ? item.parentNode : item.closest('.MainMenuReboot__panel')
    if (parentPanel) {
      parentPanel.querySelectorAll('.MainMenuReboot__panel-isOpen').forEach((openMenu) => {
        openMenu.classList.remove('MainMenuReboot__panel-isOpen')
      })
      parentPanel.querySelectorAll('.MainMenuReboot__item').forEach((menuItem) => {
        menuItem.classList.remove('isActive')
      })
    }

    if (childPanel) {
      // prevent default anchor behaviour when child panel is hidden
      if (!childPanel.offsetParent) {
        evt.preventDefault()
      }

      // since we removed all open state, we only need to add it if it was not present
      if (!wasOpen) {
        childPanel.classList.add('MainMenuReboot__panel-isOpen')
      }
    }

    // because the burger won't lose its active state, toggle is used here
    if (!wasOpen || isBurger) {
      item.classList.toggle('isActive')
    }

    if (window.handleToggle) {
      window.handleToggle(!wasOpen, childPanel)
    }
  }

  back (evt, item) {
    evt.stopPropagation()
    item.closest('.MainMenuReboot__panel').classList.remove('MainMenuReboot__panel-isOpen')
  }

  connectedCallback () {
    function addListener (element, eventType, callback, options) {
      element.addEventListener(eventType, callback, options)
      return () => element.removeEventListener(eventType, callback, options)
    }

    this.listeners = []
    this.listeners.push(addListener(document, 'click', this.close.bind(this)))
    this.listeners.push.apply(
      this.listeners,
      Array.prototype.map.call(
        this.querySelectorAll('.MainMenuReboot__item, .Hamburger'),
        (item) => addListener(item, 'click', (evt) => this.openMenu(evt, item))))
    this.listeners.push.apply(
      this.listeners,
      Array.prototype.map.call(
        this.querySelectorAll('.MainMenuReboot__back'),
        (backItem) => addListener(backItem, 'click', (evt) => this.back(evt, backItem)))
    )

    this.listeners.push.apply(
      this.listeners,
      Array.prototype.map.call(
        this.querySelectorAll('.CloseButton'),
        (el) => {
          return addListener(el, 'click', this.close.bind(this))
        }
      )
    )

    const panel = this.querySelector('.MainMenuReboot-primary')
    this.listeners.push(addListener(panel, 'scroll', (evt) => this.handleScroll(evt, panel.querySelector('[role="menu"]')), { passive: true }))
    panel.scrollTo(1, 1)

    this.listeners.push(addListener(window, 'resize', () => {
      panel.scrollTo(1, 1)
    }))

    this.listeners.push(addListener(this.querySelector('[slot="before"]'), 'click', () => {
      panel.scrollTo({ left: 0, behavior: 'smooth' })
    }))

    this.listeners.push(addListener(this.querySelector('[slot="after"]'), 'click', () => {
      panel.scrollTo({ left: panel.scrollWidth, behavior: 'smooth' })
    }))
  }

  disconnectedCallback () {
    this.listeners.forEach((el) => el.call())
  }
}

customElements.define('hb-main-navigation', MainNavigation)
