import { closest } from 'Helpers/dom'
import smoothScroll from 'Helpers/smooth-scroll'

/**
 * Adding smooth scrolling to ALL internal links.
 */
document.addEventListener('click', (e) => {
  let anchor = e.target
  if (e.target.closest) {
    anchor = e.target.closest('a[href*="#"]') || e.target
  }
  if (anchor.dataset && anchor.dataset.scroll !== 'disabled' && anchor.href) {
    const url = new URL(anchor.href)
    const isInternalLink = url.origin === location.origin && url.pathname === location.pathname
    // Checking whether the link is an anchor element, it is on the same page, and the hash is not empty
    if (anchor.nodeName === 'A' && isInternalLink && url.hash) {
      const targetId = anchor.hash.replace('#', '')
      const $target = document.getElementById(targetId)

      // Only apply smooth scroll if the target element exists
      if ($target) {
        let customOffset = 0

        // Checking if the link is within .ContentIndex-list
        if (closest(anchor, 'ContentIndex-list') !== null || closest(anchor, 'BlockBase') !== null) {
          customOffset = -40
        }

        // If consent banner is visible, we want to avoid it overlapping section
        // that we are scrolling to
        const consentBanner = document.querySelector('.ConsentBanner div')
        if (consentBanner && consentBanner.innerHTML) {
          customOffset += -consentBanner.offsetHeight
        }

        smoothScroll($target, customOffset)
        e.preventDefault()
      }
    }
  }
})
