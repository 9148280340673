export default class Footer {
  /**
   * Creates an instance of Footer.
   *
   * @memberof Footer
   */
  constructor (currentWindow) {
    this.window = currentWindow || window

    this.$consentButton = document.querySelector('.BottomMenu-link--consent')
    this.onOpenConsentPopup = this.onOpenConsentPopup.bind(this)

    this.init()
  }

  /**
   * Attaches all wanted events.
   *
   * @memberof Footer
   */
  init () {
    this.$consentButton.addEventListener('click', this.onOpenConsentPopup, false)
  }

  /**
   * Removes all listeners attached to DOM elements.
   *
   * @memberof Footer
   */
  destroy () {
    this.$consentButton.removeEventListener('click', this.onOpenConsentPopup, false)
  }

  /**
   * Open segment consent manager popup to edit
   * tracking preferences.
   *
   * @memberof Header
   */
  onOpenConsentPopup () {
    if (this.window.consentManager) {
      this.window.consentManager.openConsentManager()
    } else if (this.window.OneTrust) {
      this.window.OneTrust.ToggleInfoDisplay()
    } else {
      throw new Error('Consent manager is not available.')
    }
  }
}
