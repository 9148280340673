import debounce from 'lodash/debounce'
import Fuse from 'fuse.js'; // eslint-disable-line
import { smallerThan } from 'Helpers'
import { hideKeyboard } from 'Helpers/keys'
import smoothScroll from 'Helpers/smooth-scroll'

export default class ProductsGridSearchBox {
  /**
   * Creates an instance of ProductsGridSearchBox.
   *
   * @memberof ProductsGridSearchBox
   */
  constructor (lazyLoad, searchDebounceWait = 2000) {
    this.lazyLoad = lazyLoad
    this.$parentContainer = document.querySelector('#Products')
    this.$component = document.querySelector('.ProductsGrid')
    this.$container = document.querySelector('.ProductsGrid-row')
    this.$searchForm = document.querySelector('.ProductsGrid .Form')
    this.$searchInput = document.querySelector('.ProductsGrid .Form-input')
    this.$noResults = document.querySelector('.ProductsGrid-noResults')
    this.$prescriptionFeeCopy = document.querySelector('.ProductsGrid-prescriptionFee')

    if (!this.$container) {
      return false
    }

    this.productsHiddenByDefault = this.$container.classList.contains('is-hiddenByDefault')

    // Showing the prescription fee if any and the product grid is shown initially.
    if (!this.productsHiddenByDefault && this.$prescriptionFeeCopy) {
      this.dispatchEventByName('ProductsGrid:showPrescriptionFee')
    }

    // Attaching context to event listeners.
    this.onSearchInputClick = this.onSearchInputClick.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onInput = this.onInput.bind(this)
    this.dispatchEventByName = this.dispatchEventByName.bind(this)
    this.onTrackHandler = debounce(this.onTrack.bind(this), searchDebounceWait)

    const options = {
      shouldSort: true,
      threshold: 0.5,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title']
    }

    this.store = this.createStore()
    this.fuse = new Fuse(this.store, options)

    this.init()
  }

  /**
   * Attaches all events and kickstarts the component.
   *
   * @memberof ProductsGridSearchBox
   */
  init () {
    if (this.$searchInput) {
      this.$searchForm.addEventListener('keypress', this.onKeyPress, false)
      this.$searchInput.addEventListener('click', this.onSearchInputClick, false)
      this.$searchInput.addEventListener('input', this.onInput, false)

      this.onInput()
    }
  }

  /**
   * Removes any attached events.
   *
   * @memberof ProductsGridSearchBox
   */
  destroy () {
    if (this.$searchInput) {
      this.$searchForm.removeEventListener('keypress', this.onKeyPress, false)
      this.$searchInput.removeEventListener('click', this.onSearchInputClick, false)
      this.$searchInput.removeEventListener('input', this.onInput, false)
    }
  }

  /**
   * Dispatches an event by name, on
   * the specified DOM node.
   */
  dispatchEventByName (name, $element = this.$component) {
    if (!name) {
      return
    }

    let event = null

    if (typeof (Event) === 'function') {
      event = new Event(name, {
        bubbles: true
      })
    } else {
      event = document.createEvent('Event')
      event.initEvent(name, true, true)
    }

    $element.dispatchEvent(event)
  }

  /**
   * Scrolling the user to the parent container when
   * the user clicks on the form input. This avoids
   * having problems with the UX in mobile.
   *
   * @memberof ProductsGridSearchBox
   */
  onSearchInputClick () {
    // Only scrolling user on mobile.
    if (this.$parentContainer && smallerThan('sm')) {
      // Passing a custom offset of 20 to make it scroll
      // into the right position - where the background starts.
      smoothScroll(this.$parentContainer, 20)
    }
  }

  /**
   * Disallows the user to submit the form by pressing Enter.
   *
   * @param {Event} e
   * @returns {boolean}
   * @memberof ProductsGridSearchBox
   */
  onKeyPress (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      hideKeyboard(this.$searchInput)
      return false
    }
    return true
  }

  /**
   * Handling tracking for search input
   *
   * @memberof ProductsGridSearchBox
   */
  onTrack (value) {
    this.updateTrackingData(value)
    this.dispatchEventByName('input-search', this.$searchInput)
  }

  /**
   * Updating tracking data with recent search query
   *
   * @memberof ProductsGridSearchBox
   */
  updateTrackingData (value) {
    let trackingData
    try {
      trackingData = JSON.parse(this.$searchInput.getAttribute('data-tracking-data'))
    } catch (error) {
      console.log(error)
      return
    }

    if (trackingData && typeof trackingData === 'object') {
      trackingData.search_text = value
      this.$searchInput.setAttribute('data-tracking-data', JSON.stringify(trackingData))
    }
  }

  /**
   * Re-run the fuse search and show it on the page
   * In case the input box is empty, it shows all results.
   *
   * @memberof ProductsGridSearchBox
   */
  onInput () {
    const searchValue = this.$searchInput.value

    // If no value provided we display all elements back.
    if (!searchValue) {
      this.renderResults(this.store)

      // Hiding product grid if that was hidden initially.
      if (this.productsHiddenByDefault) {
        this.$container.classList.add('is-hiddenByDefault')
      }

      // If product grid is hidden by default, the prescription fee should also be hidden.
      if (this.productsHiddenByDefault && this.$prescriptionFeeCopy) {
        this.dispatchEventByName('ProductsGrid:hidePrescriptionFee')
      }

      // If user emptied input we don't want to send tracking event
      this.onTrackHandler.cancel()

      return
    }

    if (this.productsHiddenByDefault && this.$prescriptionFeeCopy) {
      this.dispatchEventByName('ProductsGrid:showPrescriptionFee')
    }

    this.dispatchEventByName('showall')
    this.$container.classList.remove('is-hiddenByDefault')
    this.renderResults(this.fuse.search(searchValue))

    // We are using debounced version of search handler to fire tracking
    // events for completed search queries
    this.onTrackHandler(searchValue)
  }

  /**
   * Create a list of products. The format will be like below:
   * [
   *   {
   *     'title': 'Viagra',
   *     'el': (element),
   *   }
   * ]
   *
   * @returns {array}
   * @memberof ProductsGridSearchBox
   */
  createStore () {
    const $titleElements = this.$container.querySelectorAll('.ProductsGrid-column')
    const store = []

    $titleElements.forEach(($el) => {
      // The title element is conditional
      let $title = $el.querySelector('.ProductsGridItem-title a')

      if (!$title) {
        $title = $el.querySelector('.ProductsGridItem-title')
      }

      store.push({
        title: $title.innerText.trim(),
        el: $el
      })
    })

    return store
  }

  /**
   * Remove all elements from the page, and re-add the element from the result
   *
   * @param {array} searchResults array of DOM elements
   * @memberof ProductsGridSearchBox
   */
  renderResults (searchResults) {
    const itemContainer = this.$container

    // Displaying the no-results box only if no
    // results thrown after the search.
    if (searchResults.length === 0) {
      this.$noResults.classList.remove('u-hidden')
      this.dispatchEventByName('ProductsGrid:hidePrescriptionFee')
    } else {
      this.$noResults.classList.add('u-hidden')
    }

    while (itemContainer.firstChild) {
      itemContainer.removeChild(itemContainer.firstChild)
    }

    searchResults.forEach((item) => {
      itemContainer.appendChild(item.el)
    })

    this.lazyLoad.update()
  }
}
