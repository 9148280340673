import throttle from 'lodash/throttle'
import { smallerThan } from 'Helpers'
import { closest, toggleAriaAttribute } from 'Helpers/dom'

/**
 * This class will help us to display/hide a certain element.
 * The way it works, in order to not compromise accessibility is
 * that we have a button with two spans to tell the user in
 * which state we are on, and, when the button is clicked,
 * we toggle the [aria-hidden] attribute on the targeted element.
 *
 * There are various things which are mandatory for this to work:
 *
 * - data-toggler must contain the class name of the element you want to target.
 * - the state of the button text must match with the state of the target element.
 *
 * In order to initialise the class, we need to pass in the class name
 * of the element you want to use as a Toggler button. If no class
 * provided, it will take `.js-Toggler` by default.
 *
 * @example
 *
 * <div class="ElementToDisplay" aria-hidden="true">
 *   This text will only appear when the button is clicked.
 * </div>
 *
 * <button class="js-Toggle" data-toggler="ElementToDisplay">
 *   <span>View more</span>
 *   <span hidden>View less</span>
 * </button>
 *
 * How the markup will be after clicking the button?
 *
 * <div class="ElementToDisplay" aria-hidden="false">
 *   This text will only appear when the button is clicked.
 * </div>
 *
 * <button class="js-Toggle is-toggled" data-toggler="ElementToDisplay">
 *   <span>View more</span>
 *   <span hidden>View less</span>
 * </button>
 *
 * If you want to display the Toggler button only on mobile
 * and tablets, you can pass in a data attribute like the following.
 * It is very important that you do not set aria-hidden attribute
 * on the target element in the template, since it will be
 * automatically added here for you depending on the viewport
 * the user will be on.
 *
 * <div class="ElementToDisplay">
 *   This text will only appear when the button is clicked.
 * </div>
 *
 * <button class="js-Toggle" data-toggler="ElementToDisplay" data-toggler-mobile="true">
 *   <span>View more</span>
 *   <span hidden>View less</span>
 * </button>
 *
 * It will look like...
 *
 * <div class="ElementToDisplay" aria-hidden="true" style="display: none">
 *   This text will only appear when the button is clicked.
 * </div>
 *
 * <button class="js-Toggle" data-toggler="ElementToDisplay" data-toggler-mobile="true">
 *   <span>View more</span>
 *   <span hidden>View less</span>
 * </button>
 *
 * @export
 * @class Toggler
 */
export default class Toggler {
  /**
   * Creates an instance of Toggler.
   *
   * @param {String} [className='.js-Toggler']
   * @memberof Toggler
   */
  constructor (lazyLoad, className = 'js-Toggler') {
    this.lazyLoad = lazyLoad
    this.className = className
    this.$togglers = document.querySelectorAll(`.${className}`)

    // Bind context to event handlers.
    this.onClick = this.onClick.bind(this)
    this.onResizeHandler = throttle(this.onResize.bind(this), 500)

    // Kicking off the component.
    this.init()

    // Triggering event manually to set initial state on togglers.
    this.onResize()
  }

  /**
   * Sets all event listeners for each toggler element.
   *
   * @memberof Toggler
   */
  init () {
    if (!this.$togglers) {
      return
    }

    this.$togglers.forEach(($toggler) => {
      // Hiding each element if aria-hidden was set to true initially.
      const $element = document.querySelector(`.${$toggler.dataset.toggler}`)

      if (!$element) {
        return
      }

      const hasAriaHidden = !!$element.hasAttribute('aria-hidden')
      const isAriaHiddenTrue = $element.getAttribute('aria-hidden') === 'true'

      if (hasAriaHidden && isAriaHiddenTrue) {
        $element.style.display = 'none'
      }

      $toggler.addEventListener('click', this.onClick, false)
    })

    window.addEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * Removes all event listeners for each toggler element.
   *
   * @memberof Toggler
   */
  destroy () {
    if (!this.$togglers) {
      return
    }

    this.$togglers.forEach(($toggler) => {
      $toggler.removeEventListener('click', this.onClick, false)
    })

    window.removeEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * Toggles the aria-hidden attribute if exists and if so
   * toggles style.display on the element to hide it or display it.
   *
   * @static
   * @param {HTMLElement} $element
   * @returns {Boolean}
   * @memberof Toggler
   */
  static toggleHidden ($element) {
    const $target = $element

    if ($target.hasAttribute('aria-hidden')) {
      toggleAriaAttribute($target, 'aria-hidden')
      $target.style.display = ($target.style.display === 'none') ? '' : 'none'
      return true
    }
    return false
  }

  /**
   * Toggles the button text.
   *
   * @static
   * @param {HTMLElement} $button
   * @memberof Toggler
   */
  static toggleButtonText ($button) {
    const $visibleText = $button.querySelector(':not([hidden])')
    const $hiddenText = $button.querySelector('[hidden]')
    $visibleText.setAttribute('hidden', '')
    $hiddenText.removeAttribute('hidden')
  }

  /**
   * This method performs some things when the toggler button is clicked:
   *
   * - Toggles the aria-hidden attribute of the target class.
   * - Toggles the display none style on the target element.
   * - Toggles the hidden attribute of the spans within the button.
   * - Toggles the `is-toggled` class on the button.
   *
   * @param {HTMLEvent} e
   * @memberof Toggler
   */
  onClick (e) {
    let $button = e.target

    if (!e.target.classList.contains(this.className)) {
      $button = closest(e.target, this.className)
    }

    // Get the element by data-toggler attribute on the button.
    const $element = document.querySelector(`.${$button.dataset.toggler}`)

    if (!$element) {
      return
    }

    // Breaking if element could not be hidden.
    if (!Toggler.toggleHidden($element)) {
      return
    }

    // Adding is-toggled class to the Button in case you
    // want to target this by CSS to perform an animation for example.
    $button.classList.toggle('is-toggled')

    // Toggling the button text
    if ($button.dataset.togglerButton !== 'false') {
      Toggler.toggleButtonText($button)
    }

    // Loading any images within the toggler.
    this.lazyLoad.update()
  }

  /**
   * Loops through each toggler element and checks whether it is
   * set only to be shown only on mobile. If so and the user is
   * actually on xs or sm breakpoints, then it hides the target
   * element, otherwise it displays it.
   *
   * When it comes to the toggler button itself, we leave the
   * display decision to the user, as it is easier and more explicit
   * if they use the utility classes defined at `_visibility.scss`
   * on the UI toolkit.
   *
   * @memberof Toggler
   */
  onResize () {
    this.$togglers.forEach(($button) => {
      const $target = document.querySelector(`.${$button.dataset.toggler}`)
      const isMobileToggler = $button.dataset.togglerMobile === 'true'
      const breakpoint = $button.dataset.togglerBreakpoint

      if (!$target || !isMobileToggler || !breakpoint) {
        return
      }

      if (breakpoint === 'all' || (!!breakpoint && smallerThan(breakpoint))) {
        $target.style.display = 'none'
        $target.setAttribute('aria-hidden', 'true')
      } else {
        // Displaying this.$text on desktop.
        $target.style.display = ''
        $target.setAttribute('aria-hidden', 'false')
      }
    })
  }
}
