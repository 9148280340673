export default function insertManifest (win = window, nav = navigator) {
  win.addEventListener('load', function () {
    // We had problems with date picker on PWA desktop, hence filtering desktop
    if (nav.userAgent.indexOf('Mobile') !== -1) {
      const country = document.documentElement.getAttribute('data-country')
      if (country) {
        const manifestTag = document.createElement('link')
        manifestTag.setAttribute('rel', 'manifest')
        manifestTag.setAttribute('href', `/${country}/manifest.json`)
        document.head.appendChild(manifestTag)
      }
    }
  })
}
