import Clipboard from 'clipboard'

export default class SharingButtons {
  /**
   * Creates an instance of SharingButtons.
   *
   * @memberof SharingButtons
   */
  constructor () {
    this.$copyLink = document.querySelector('.SharingButton-copy')
    this.urlToShare = window.location.href

    if (this.$copyLink) {
      this.$tooltip = this.$copyLink.querySelector('[data-tooltip]')

      if (this.$tooltip) {
        this.ariaLabel = this.$tooltip.getAttribute('aria-label')
      }
    }

    // Binding the context to event methods.
    this.onCopyClick = this.onCopyClick.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Initialises the component.
   *
   * @memberof SharingButtons
   */
  init () {
    if (this.$copyLink) {
      this.$copyLink.addEventListener('click', this.onCopyClick, false)

      this.clipboardInstance = new Clipboard(this.$copyLink, {
        text: () => this.urlToShare
      })
    }

    if (this.$tooltip) {
      this.$tooltip.addEventListener('mouseout', this.onMouseOut, false)
    }
  }

  /**
   * Removes all event listeners.
   *
   * @memberof SharingButtons
   */
  destroy () {
    if (this.$copyLink) {
      this.$copyLink.removeEventListener('click', this.onCopyClick, false)
      this.clipboardInstance.destroy()
    }

    if (this.$tooltip) {
      this.$tooltip.removeEventListener('mouseout', this.onMouseOut, false)
    }
  }

  /**
   * Copies the URL to the clipboard and changes
   * the text on the aria-label to notify the user.
   *
   * @memberof SharingButtons
   */
  onCopyClick () {
    this.$tooltip.setAttribute('aria-label', 'URL copied to the clipboard')
  }

  /**
   * Changes the aria-label back to its original value.
   *
   * @param {any} e
   * @memberof SharingButtons
   */
  onMouseOut (e) {
    // The animation time to hide the tooltip is 180ms so,
    // we change the aria-label 300ms after to avoid strange behaviors.
    window.setTimeout(() => {
      this.$tooltip.setAttribute('aria-label', this.ariaLabel)
    }, 300)

    e.stopPropagation()
  }
}
