import { closest } from 'Helpers/dom'

export default class CtaOptions {
  /**
   * Creates an instance of CtaOptions.
   * @memberof CtaOptions
   */
  constructor () {
    this.$ctaOptionsForms = document.querySelectorAll('.CtaOptions form')

    // Binding context to event methods.
    this.onChange = CtaOptions.onChange.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Initialises the component.
   *
   * @memberof CtaOptions
   */
  init () {
    if (!this.$ctaOptionsForms) {
      return
    }
    // Force a change event on the first load.
    const e = document.createEvent('HTMLEvents')
    e.initEvent('change', false, true)

    this.$ctaOptionsForms.forEach((el) => {
      el.addEventListener('change', this.onChange, false)
      el.dispatchEvent(e)
    })
  }

  /**
   * Removes all attached events.
   *
   * @returns
   * @memberof CtaOptions
   */
  destroy () {
    if (!this.$ctaOptionsForms) {
      return
    }

    this.$ctaOptionsForms.forEach((el) => {
      el.removeEventListener('change', this.onChange, false)
    })
  }

  /**
   * Hide all CTAs but the selected option one.
   *
   * @memberof CtaOptions
   */
  static onChange (event) {
    // Fetch the current .CtaOptions form in order to avoid to hide unrelated CTAs
    const ctaOptionsForm = closest(event.target, 'CtaOptions')

    // Hidding all CTAs within the current .CtaOptions form.
    ctaOptionsForm.querySelectorAll('[class*="CtaOptions-value"]').forEach((el) => {
      const current = el
      current.style.display = 'none'
    })

    // Show selected option CTA only.
    const checkedValue = ctaOptionsForm.querySelector('input[name=CtaOptions-radios]:checked').value
    document.querySelector(`[class*="CtaOptions-value${checkedValue}"]`).style.display = ''

    const alternativeCTAUrlEnabled = document.querySelector('.CtaOptions-alternativeCTAUrl-enabled')
    if (this.$ctaOptionsForms.length > 0 && alternativeCTAUrlEnabled) {
      this.addUrlParams(checkedValue)
    }
  }

  /**
   * Adds selected service Id parameters to url to create the link for the secondary service page
   *
   * @memberof CtaOptions
   */
  addUrlParams (checkedValue) {
    let selectedButton = document.querySelector(`.CtaOptions-value${checkedValue}`)
    let dataLink = selectedButton.getAttribute('data-link')

    if (dataLink && dataLink.length > 0) {
      let serviceId = dataLink.replace(/^.*\//, '') // gets the service id from the data-link url
      let url = new URL(selectedButton.href)
      url.searchParams.set('serviceId', serviceId) // adds query param with serviceId
      selectedButton.href = url
    }
  }
}
