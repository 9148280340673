export default class InfoOptionBox {
  /**
   * Creates an instance of InfoOptionBox.
   *
   * @memberof InfoOptionBox
   */
  constructor () {
    this.$mainMenu = document.querySelector('.MainMenu')
    this.$listItems = document.querySelectorAll('.MainMenu-primary > li')
    this.$infoOptionBox = document.querySelector('.InfoOptionBox')

    // Binding the context to event methods.
    this.positionBox = this.positionBox.bind(this)
  }

  /**
   * Checks whether we have to move the element
   * to the bottom or not.
   *
   * @returns {Boolean}
   * @memberof InfoOptionBox
   */
  isBottom () {
    if (!this.$mainMenu) {
      return false
    }
    const mainMenuHeight = this.$mainMenu.offsetHeight
    let listItemsHeight = 0

    this.$listItems.forEach((item) => {
      listItemsHeight += item.offsetHeight
    })

    return mainMenuHeight > listItemsHeight
  }

  /**
   * Moves the element to the bottom of the container
   * if the container is bigger than the actual content.
   *
   * @memberof InfoOptionBox
   */
  positionBox () {
    if (!this.$infoOptionBox) {
      return
    }

    if (this.isBottom()) {
      this.$infoOptionBox.classList.add('InfoOptionBox--bottom')
    } else {
      this.$infoOptionBox.classList.remove('InfoOptionBox--bottom')
    }
  }
}
