export default class ReviewCard {
  constructor () {
    this.$reviews = document.querySelector('.PatientReviews')

    this.init()
  }

  /**
   * Toggles visibility
   * @param event
   */
  onShowMoreBtnClick (event) {
    event.target
      .closest('.ReviewCard')
      .classList
      .toggle('ReviewCard--expanded')
  }

  /**
   * add event listeners
   */
  init () {
    if (!this.$reviews) {
      return
    }
    this.$reviewCardShowMoreButtons = document.querySelectorAll('.ReviewCard-readMoreBtn')
    this.$reviewCardShowMoreButtons.forEach(($btn) => {
      $btn.addEventListener('click', this.onShowMoreBtnClick, false)
    })
  }

  /**
   * remove event listeners
   */
  destroy () {
    if (!this.$reviewCardShowMoreButtons) {
      return
    }

    this.$reviewCardShowMoreButtons.forEach(($btn) => {
      $btn.removeEventListener('click', this.onShowMoreBtnClick, false)
    })
  }
}
