/**
 * Registers the service worker on load
 */
export function registerSW (nav = navigator, winLoc = window.location, errorHandler) {
  if ('serviceWorker' in nav) {
    window.addEventListener('load', () => {
      const pseudoLocale = document.documentElement.getAttribute('data-pseudo-locale')
      const locale = pseudoLocale === 'sd_UK' ? 'uk' : winLoc.pathname.split('/')[1] // sduk should work with/without the prefix in url
      nav.serviceWorker
        .register(`/${locale}/assets/service-worker.js`)
        .catch((registrationError) => {
          console.error(`Ouch! Service worker registration failed ${registrationError}`)
          errorHandler && errorHandler()
        })
    })
  }
}

export default registerSW
