import { smallerThan } from 'Helpers'
import { isEnter, isEscape } from 'Helpers/keys'
import { closest, elementInViewport } from 'Helpers/dom'

export default class PrimaryNavigation {
  /**
   * Creates an instance of PrimaryNavigation.
   *
   * @memberof PrimaryNavigation
   */
  constructor () {
    this.classes = {
      panel: 'MainMenu-panel',
      link: 'MainMenu-link',
      backButton: 'MainMenu-backButton',

      // MainMenu-panel states.
      isExpanded: 'is-expanded',
      isHidden: 'is-hidden',
      isOpened: 'is-opened',
      isOutsideViewPort: 'is-outsideViewPort'
    }

    this.$mainMenu = document.querySelector('.MainMenu')
    this.$mainMenuLink = document.querySelector('.MainMenu-link')

    // Binding the context to event methods.
    this.events = this.events.bind(this)
    this.onBodyClick = this.onBodyClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)

    // Kicking off the component.
    this.init()
  }

  /**
   * Initialises the class just by attaching a click event.
   *
   * @memberof PrimaryNavigation
   */
  init () {
    if (this.$mainMenu) {
      // Listening clicks anywhere in the document and if the element
      // is not within the navigation, it closes any opened menus.
      document.body.addEventListener('click', this.onBodyClick, false)
      this.$mainMenu.addEventListener('click', this.events, false)
      this.$mainMenu.addEventListener('keydown', this.onKeyDown, false)
    }
  }

  /**
   * Removes all listeners attached to DOM elements.
   *
   * @memberof PrimaryNavigation
   */
  destroy () {
    document.onKeyDown = null
    if (this.$mainMenu) {
      document.body.removeEventListener('click', this.onBodyClick, false)
      this.$mainMenu.removeEventListener('click', this.events, false)
      this.$mainMenu.removeEventListener('keydown', this.onKeyDown, false)
    }
  }

  /**
   * Calls the right methods for the target element.
   *
   * @param {Event} e
   * @memberof PrimaryNavigation
   */
  events (e) {
    this.onLinkClick(e)
    this.onBackClick(e)
  }

  /**
   * Closes the menu if the user clicked somewhere outside of it.
   *
   * @param {Event} e
   * @memberof PrimaryNavigation
   */
  onBodyClick (e) {
    // Only clicking on desktop devices should close any opened menus.
    if (!this.$mainMenu.contains(e.target) && !smallerThan('lg')) {
      this.closeMenus()
    }
  }

  /**
   * Pushes to the left the current panel and pulls in the next
   * one if and only if the user clicked on a PrimaryNavigation link.
   *
   * @param {Event} e
   * @memberof PrimaryNavigation
   */
  onLinkClick (e) {
    if (!e.target.classList.contains(this.classes.link)) {
      return
    }

    // Making sure we follow the link if the href doesn't contain a hash.
    if (e.target.href && e.target.href !== '') {
      return
    }

    if (smallerThan('lg')) {
      // If mobile we just toggle the classes to open the new panel.
      this.openMenu(e)
    } else {
      // Closing any opened menus and adding a class for desktop to rotate the chevron.
      const isAlreadyExpanded = e.target.className.indexOf(this.classes.isExpanded) > -1

      this.closeMenus(e)
      if (!isAlreadyExpanded) {
        this.openMenu(e)
      }

      if (!elementInViewport(e.target.nextElementSibling)) {
        const $nextPanel = e.target.nextElementSibling
        $nextPanel.classList.add(this.classes.isOutsideViewPort)
      }
    }

    e.preventDefault()
  }

  /**
   * Pushes the current panel to the right and pulls in the previous one
   * from the left if and only if the user clicked on the back button.
   *
   * @param {Event} e
   * @memberof PrimaryNavigation
   */
  onBackClick (e) {
    if (!e.target.classList.contains(this.classes.backButton)) {
      return
    }

    // Finds the closest panel and toggles its classes.
    const $currentPanel = closest(e.target, this.classes.panel)
    this.toggleClasses($currentPanel)

    // Finds the parent panel and puts back the is-opened class.
    const $parentPanel = closest($currentPanel, 'MainMenu-panel')
    $parentPanel.classList.add(this.classes.isOpened)

    e.preventDefault()
  }

  /**
   * Open the next panel from the clicked link.
   *
   * @param {Event} e
   * @memberof PrimaryNavigation
   */
  openMenu (e) {
    const $parentPanel = closest(e.target, 'MainMenu-panel')
    $parentPanel.classList.remove(this.classes.isOpened)

    e.target.classList.toggle(this.classes.isExpanded)

    // Search for the next paneland if found
    // toggles its classes to display it on the screen.
    if (e.target.nextElementSibling) {
      this.toggleClasses(e.target.nextElementSibling)
    }
  }

  /**
   * Closes all expanded menus (if any).
   *
   * @memberof PrimaryNavigation
   */
  closeMenus (e) {
    this.$mainMenu
      .querySelectorAll(`.${this.classes.isExpanded}`)
      .forEach((item) => {
        if (item === e) {
          return
        }

        item.classList.remove(this.classes.isExpanded)
        if (item.nextElementSibling) {
          this.toggleClasses(item.nextElementSibling)
        }
      })
  }

  /**
   * Toggles a panel status from is-hidden to
   * is-opened and viceversa.
   *
   * @param {HTMLElement} element
   * @memberof PrimaryNavigation
   */
  toggleClasses (element) {
    element.classList.toggle(this.classes.isHidden)
    element.classList.toggle(this.classes.isOpened)
  }

  /**
  * Open the popover main menu if the user pressed Enter.
  *
  * @param {Event} e
  * @memberof PrimaryNavigation
  */
  onKeyDown (e) {
    if (isEnter(e)) {
      this.onLinkClick(e)
    } else if (isEscape(e)) {
      this.closeMenus()
    }
  }
}
