import { smallerThan } from 'Helpers'

export default class ServiceHeader {
  /**
   * Creates an instance of ServiceHeader.
   *
   * @memberof ServiceHeader
   */
  constructor () {
    this.$container = document.querySelector('.ServiceHeader')
    this.$containerOffSet = 80
    this.calculateMargin = this.calculateMargin.bind(this)
    // Kicking off component.
    this.init()
  }

  /**
   * Init
   *
   * @memberof ServiceHeader
   */
  init () {
    if (this.$container) {
      this.calculateMargin()
      window.addEventListener('resize', this.calculateMargin)
    }
  }

  calculateMargin () {
    if (!smallerThan('md')) {
      const offset = -((this.$container.offsetHeight - this.$containerOffSet) / 10)
      this.$container.style.marginTop = `${offset}rem`
    } else {
      this.$container.style.marginTop = 0
    }
  }

  /**
   * Removes all listeners attached to DOM elements.
   *
   * @memberof ServiceHeader
   */
  destroy () {
    window.removeEventListener('resize', this.calculateMargin, false)
  }
}
