import TrackingData from './tracking-data'
import TrackingProvider from './providers/segment-provider'
import Cookies from 'js-cookie'

/**
  * Timeout value for how long are we waiting for tracking event,
  * before following link and opening new page
  */
export const FOLLOW_LINK_DELAY = 500
export const AFFILIATES_COOKIE_NAME = 'zaf'

/**
 * This class is just a helper to track events.
 * All you need to do is make sure your markup follows
 * the example underneath.
 *
 * Just note that this will only track events on click and form submit.
 * For any other event listeners you may need to extend this class as well
 * as the TrackingData class.
 *
 * data-tracking-event-types attribute is optional, if it's not specified
 * we are tracking all DOM event types.
 *
 * @example
 *
 * <a
 *   href="#"
 *   data-tracking-event="EventName"
 *   data-tracking-event-types='["click"]'
 *   data-tracking-data='{ "json": "string" }'>
 *     example
 * </a>
 *
 * @export
 * @class Tracking
 */
export default class Tracking {
  /**
   * Creates an instance of Tracking.
   *
   * @memberof Tracking
   */
  constructor (currentWindow, authCheck) {
    this.window = currentWindow || window

    // Bind context to event handlers.
    this.onTrack = this.onTrack.bind(this)
    this.onPageView = this.onPageView.bind(this)
    this.handleAffiliateCookie = this.handleAffiliateCookie.bind(this)

    this.trackingProvider = new TrackingProvider()

    // Tracking if analytics is fully loaded and ready
    this.trackingProvider.getAnalyticsReadyPromise().then(this.handleAffiliateCookie)

    if (window.zavamed && window.zavamed.trackingData) {
      window.zavamed.trackingData.is_logged_in = authCheck.isUserSignedIn()
    }

    // Kicking off the component.
    this.init()
  }

  /**
   * Checks if the functional cookies are disallowed
   * and removes the 'zaf' cookie if it is so.
   * - If `tracking-cookie` doesn't exist(meaning hasn't set
   *   any preference on consent-manager), it doesn't delete `zaf` cookie
   * - If `tracking-cookie` exists, the function deletes `zaf` cookie based on
   *   `functional` value (true or false)
   */
  handleAffiliateCookie () {
    if (this.trackingProvider.getTrackingPreferences() &&
        !this.trackingProvider.isCustomTrackingEnabled('functional')) {
      Cookies.remove(AFFILIATES_COOKIE_NAME, { path: '' })
    }
  }

  /**
   * Adds listeners to DOM elements.
   *
   * @memberof Tracking
   */
  init () {
    document.body.addEventListener('click', this.onTrack, false)
    document.body.addEventListener('submit', this.onTrack, false)
    document.body.addEventListener('input-search', this.onTrack, false)
    document.body.addEventListener('page-view', this.onPageView, false)
  }

  /**
   * Removes all event listeners.
   *
   * @memberof Tracking
   */
  destroy () {
    document.body.removeEventListener('click', this.onTrack, false)
    document.body.removeEventListener('submit', this.onTrack, false)
    document.body.removeEventListener('input-search', this.onTrack, false)
    document.body.removeEventListener('page-view', this.onPageView, false)
  }

  /**
   * Tracks an event on click if and only if there
   * is an element with defined with data-tracking-event.
   *
   * @param {HTMLEvent} event
   * @memberof Tracking
   */
  onTrack (event) {
    if (!TrackingProvider.isSetup()) {
      return
    }

    const trackedElement = this.trackedElement(event)
    if (!trackedElement) {
      return
    }

    this.handleAffiliateCookie()

    const { trackingEvent, trackingData } = TrackingData.get(trackedElement)

    TrackingProvider.trackEvent(trackingEvent, trackingData)

    const link = this.getLinkInfo(trackedElement)
    const followUrl = link && link.href

    if (followUrl && !followUrl.startsWith('#')) {
      event.preventDefault()
      this.delay()
        .then(() => {
          this.follow(followUrl, link.target)
        }).catch((e) => {
          // We change url straight away if there was an error during loading
          // analytics library (e.g. when tracking is blocked in user browser)
          this.follow(followUrl, link.target)
          throw e
        })
    }
  }

  /**
   * Tracks an pageViews based on custom event
   *
   * @param {HTMLEvent} event
   * @memberof Tracking
   */
  onPageView (event) {
    const pageMetadata = TrackingData.composeMetadata()
    const reqId = TrackingData.getReqID()

    if (reqId) {
      TrackingProvider.updateUserTraits(
        null,
        {
          affiliate: {
            reqId,
            timestamp: Date.now()
          }
        })
    }

    TrackingProvider.pageViewEvent(pageMetadata)
  }

  /**
   * Returns element if it's supposed to be tracked
   *
   * @param {HTMLEvent} event
   * @memberof Tracking
   */
  trackedElement (event) {
    // We are trying to find element with non-empty data-tracking-event
    // attribute in ancestors of event target.
    const trackedElement = event.target.closest('[data-tracking-event]')

    // If there is no trackedElement, it means click (or other tracking event)
    // happened outside of tracked element and we are not interested in it.
    if (!trackedElement) {
      // if element is not tracked, try to find if it's a plain link
      const trackedElementLink = this.trackedElementLink(event)
      if (trackedElementLink !== null) {
        return trackedElementLink
      }
      return null
    }

    if (!this.hasTrackingEventAttribute(trackedElement)) {
      return null
    }

    const trackedEventTypes = this.trackedEventTypes(trackedElement, event)

    // Tracked element can produce several types of DOM events
    // depending on user action, but we may be interested just in one
    // type. So we have a way to filter DOM events with attribute
    // data-tracking-event-types.
    if (trackedEventTypes.length && !trackedEventTypes.includes(event.type)) {
      return null
    }

    return trackedElement
  }

  /**
   * Returns element if it's supposed to be tracked
   *
   * @param {HTMLEvent} event
   * @returns {HTMLElement | null}
   * @memberof Tracking
   */
  trackedElementLink (event) {
    if (event.target.tagName === 'A' && !!event.target.getAttribute('href')) {
      return event.target
    }
    return null
  }

  hasTrackingEventAttribute (trackedElement) {
    return !!(trackedElement.getAttribute('data-tracking-event'))
  }

  /**
   * Returns tracking event types if they're present
   *
   * @param {HTMLElement} trackedElement
   * @returns {[String]} trackedEventTypes
   * @memberof Tracking
   */
  trackedEventTypes (trackedElement) {
    let trackedEventTypes = []
    const trackingEventTypesElement = trackedElement.getAttribute('data-tracking-event-types')

    if (trackingEventTypesElement) {
      try {
        trackedEventTypes = JSON.parse(trackingEventTypesElement)
        if (!Array.isArray(trackedEventTypes)) {
          throw new Error('Parsed `data-tracking-event-types` is not an array')
        }
      } catch (error) {
        console.error('Tracking.trackedEventTypes(): ', error)
        return trackedEventTypes
      }
    }
    return trackedEventTypes
  }

  /**
    * Waits for analytics to be fully loaded to start tracking data
    *
    * @memberof Tracking
    */
  delay () {
    return new Promise((resolve) => {
      // Delays the url change to wait for tracking to complete,
      // before browser will follow new URL.
      setTimeout(() => {
        resolve()
      }, FOLLOW_LINK_DELAY)
    })
  }

  /**
   * Handel target=_blank for delayFollow calls.
   *
   * @param url
   * @param target
   */
  follow (url, target) {
    if (target === '_blank') {
      this.window.open(url)
    } else {
      this.window.location = url
    }
  }

  /**
   * Returns url present in a link element
   *
   * @param {HTMLElement} element
   * @returns {string | null} url
   * @memberof Tracking
   */
  getLinkInfo (element) {
    const link = element.closest('a')
    if (link !== null && link.getAttribute('href') !== null) {
      return {
        href: link.getAttribute('href'),
        target: link.getAttribute('target')
      }
    }
    return null
  }

  /**
   * Redirects to URL by using the DOM and adds the ability for
   * passing tracking parameters, based on tracking provider.
   *
   * @param {String} url - URL for redirection to
   * @param {Object} [tracking={}] - Optional: tracking data
   * @param {String} tracking.eventName - The event name for the tracking object, eg. "button click"
   * @param {Array} tracking.eventType - Optional: type of event, eg. ["click"]
   * @param {Object|String} tracking.data - Object including all the necessary tracking properties passed upon redirect
   */
  static customRedirectWithTracking (url, tracking = {}) {
    if (typeof document === 'undefined') {
      return
    }

    const a = document.createElement('a')
    a.href = url
    const linkId = Math.random().toString(36)
    a.id = linkId
    if (tracking && tracking.eventName && tracking.data) {
      a.dataset.trackingEvent = tracking.eventName

      if (tracking.eventType) {
        a.dataset.trackingEventType = tracking.eventType
      }

      if (typeof tracking.data === 'object') {
        tracking.data = JSON.stringify(tracking.data)
      }

      a.dataset.trackingData = tracking.data
    }

    document.body.appendChild(a)
    document.getElementById(linkId).click()
  }
}
