// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
// this iife polyfills node remove for IE 9 and up
// this is the default polyfill from the MDN page

[Element.prototype, CharacterData.prototype, DocumentType.prototype].forEach((item) => {
  if (item.hasOwnProperty('remove')) {
    return
  }

  Object.defineProperty(item, 'remove', {
    configurable: true,
    enumerable: true,
    writable: true,
    value () {
      if (this.parentNode !== null) {
        this.parentNode.removeChild(this)
      }
    }
  })
})
