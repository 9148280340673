/**
 * NodeList.forEach polyfill
 * Info: https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */

/* eslint-disable */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, argument) {
    argument = argument || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(argument, this[i], i, this);
    }
  };
}
