// This file will be used only to import js files.
// Bear in mind vendors should be imported first.

// Vendors.
import 'what-input'
import LazyLoad from 'vanilla-lazyload/dist/lazyload'
import 'whatwg-fetch'

import AuthCheck from 'Helpers/auth'

// Shims.
import 'Shims'

// Pattern imports:
import Accordion from 'Patterns/accordion/accordion'
import CarouselThumbnails from 'Patterns/carousel-thumbnails/carousel-thumbnails'
import ContentIndex from 'Patterns/content-index/content-index'
import ContentMeta from 'Patterns/content-meta/content-meta'
import CtaOptions from 'Patterns/cta-options/cta-options'

import DeliveryOptions from 'Patterns/delivery/options/options'
import DeliveryMessage from 'Patterns/delivery/message/message'
import DeliveryModesSettings from 'Patterns/delivery/settings/delivery-modes-settings'

import Footer from 'Patterns/footer/footer'
import Header from 'Patterns/header/header'
import IconMenu from 'Patterns/header/icon-menu/icon-menu'
import RegulationAboveFold from 'Patterns/regulation-above-fold/regulation-above-fold'
import InfoOptionDesktop from 'Patterns/header/info-option-desktop/info-option-desktop'
import SharingButtons from 'Patterns/sharing-buttons/sharing-buttons'
import TrustpilotReviews from 'Patterns/trustpilot-reviews/trustpilot-reviews'
import ProductsGrid from 'Patterns/products-grid/products-grid'
import ReviewCard from 'Patterns/patient-reviews/review-card/review-card'
import ServiceHeader from 'Patterns/service/header/header'
import ServiceProductSearchBox from 'Patterns/products-grid/products-grid-search-box/products-grid-search-box'
import ProductIndex from 'Patterns/product/product-index/product-index'
import ProductIndexSimplified from 'Patterns/product/product-index-simplified/product-index-simplified'
import BrowserUpdate from 'Patterns/browser-update/browser-update'

// Component imports:
import Toggler from 'Shared/components/toggler'
import Tracking from 'Shared/components/tracking/tracking'
import 'Shared/ce/close-button.js'
import 'Shared/ce/main-navigation.js'

// Ally imports:
import SkipToContent from 'Shared/ally/skip-to-content'

// Service worker.
import registerSW from './register-sw'

// Manifest for PWA
import insertManifest from './helpers/control-manifest'
insertManifest()

registerSW()

// Vendors
// ********************************************************

const lazyLoad = new LazyLoad()

// Exposing lazyload so we can use lazyLoad.loadAll()
// later on in our visual diff test suite.
window.lazyLoad = lazyLoad

const DeliveryModesSettingsInstance = new DeliveryModesSettings()

// Patterns
// ********************************************************
new Accordion()
new ContentIndex()
new ContentMeta()
new CtaOptions()
new DeliveryOptions(DeliveryModesSettingsInstance)
new DeliveryMessage(DeliveryModesSettingsInstance)
new Footer()
new Header()
new IconMenu()
new InfoOptionDesktop()
new RegulationAboveFold(lazyLoad)
new SharingButtons()
new TrustpilotReviews()
new ProductsGrid(lazyLoad)
new ServiceHeader()
new ServiceProductSearchBox(lazyLoad)
new ProductIndex()
new ProductIndexSimplified()
// CarouselThumbnails has to be placed at last to initialize Flickity
new CarouselThumbnails()
new BrowserUpdate()
new ReviewCard()

// Components
// ********************************************************

new Toggler(lazyLoad)
new Tracking(window, new AuthCheck(document.getElementById('zava-main-js').getAttribute('data-client-id')))

// Ally
// ********************************************************

new SkipToContent()
