
export default class ContentMeta {
  /**
   * Creates an instance of ContentMeta.
   *
   * @memberof ContentMeta
   */
  constructor () {
    this.$button = document.querySelector('.ContentMeta-button')

    // Attaching context to event handlers.
    this.onButtonClick = this.onButtonClick.bind(this)

    // Kicking off component.
    this.init()
  }

  /**
   * Attaches events to elements.
   *
   * @memberof ContentMeta
   */
  init () {
    if (this.$button) {
      this.$button.addEventListener('click', this.onButtonClick, false)
    }
  }

  /**
   * Removes any attached events.
   *
   * @memberof ContentMeta
   */
  destroy () {
    if (this.$button) {
      this.$button.removeEventListener('click', this.onButtonClick, false)
    }
  }

  /**
   * Displays the next 5 hidden elements when the button is clicked.
   * If all the elements shown, it will remove the button and its
   * event listeners.
   *
   * @memberof ContentMetas
   */
  onButtonClick () {
    const $hiddenSources = [].slice.call(document.querySelectorAll('.ContentMeta-hidden[aria-hidden="true"]'))
    const $firstSources = $hiddenSources.slice(0, 5)

    $firstSources.forEach(($source) => {
      $source.setAttribute('aria-hidden', 'false')
      $source.removeAttribute('hidden')
    })

    if ($hiddenSources.length <= 5) {
      document.querySelector('.ContentMeta-show').remove()
      this.destroy()
    }
  }
}
