import HTMLElementShim from './ce-shim'

const template = document.createElement('template')
template.innerHTML = `
<style>
.svgCross {
  height: 1em;
  display: block;
  cursor: pointer;
  padding: 16px 12px 12px 12px;
}

#closeButton {
  background-color: transparent;
  border: none;
}
</style>
<button id="closeButton">
<svg class="svgCross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="" fill="currentColor" d="M18.3 5.70996C18.1131 5.5227 17.8595 5.41747 17.595 5.41747C17.3305 5.41747 17.0768 5.5227 16.89 5.70996L12 10.59L7.10997 5.69996C6.92314 5.5127 6.66949 5.40747 6.40497 5.40747C6.14045 5.40747 5.8868 5.5127 5.69997 5.69996C5.30997 6.08996 5.30997 6.71996 5.69997 7.10996L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10996C18.68 6.72996 18.68 6.08996 18.3 5.70996Z"></path>
</svg>
</button>`

class CloseButton extends HTMLElementShim {
  constructor () {
    super()

    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.appendChild(template.content.cloneNode(true))

    const identifierState = sessionStorage.getItem(this.identifier)
    if (identifierState === null) { // Show if there is nothing in session storage
      const banner = document.querySelector(`.${this.identifier}`)
      if (banner) {
        banner.style.display = 'block'
      }

      const bannerLink = document.querySelector(`.${this.identifier} a`)
      if (bannerLink) {
        bannerLink.addEventListener('click', () => {
          sessionStorage.setItem(this.identifier, true)
        }) // so if you click the link in the banner, it won't show when you return to the page
      }
    }
  }

  connectedCallback () {
    this.$button = document.querySelector('hb-close-button').shadowRoot.querySelector('#closeButton')

    this.$button.addEventListener('click', () => {
      const banner = document.querySelector(`.${this.identifier}`)
      if (banner) {
        banner.style.display = 'none'
        sessionStorage.setItem(this.identifier, true)
      }
    })
  }

  disconnectedCallback () {
    this.destroy()
  }

  get identifier () {
    return this.getAttribute('identifier')
  }
}

customElements.define('hb-close-button', CloseButton)
