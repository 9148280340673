import { closest } from 'Helpers/dom'
import DeliveryOptionsTemplates from './templates'

export default class DeliveryOptions {
  /**
   * Creates an instance of DeliveryOptions.
   *
   * @memberof DeliveryOptions
   */
  constructor (deliveryModes) {
    this.$deliveryOptions = document.querySelector('.DeliveryOptions')
    // Attaching context to event handlers.
    this.onLoad = this.onLoad.bind(this)
    this.onInfoClick = DeliveryOptions.onInfoClick.bind(this)
    this.renderTable = this.renderTable.bind(this)
    this.deliveryModes = deliveryModes
    // Kicking off the component.
    if (!DeliveryOptions.manual) {
      this.init()
    }
  }

  /**
   * Initialises the component.
   *
   * @memberof DeliveryOptions
   */
  init () {
    if (!this.$deliveryOptions || !this.deliveryModes) {
      return
    }

    this.initDeliverySettings()
    if (document.readyState !== 'loading') {
      this.onLoad()
      return
    }

    document.addEventListener('DOMContentLoaded', () => {
      this.onLoad()
    })
  }

  /**
   * instantiates all the delivery settings set in this.deliveryModes
   * initialises DeliveryOptionsTemplates
   * check shared/site/snippets/delivery-modes.php snippet for details
   *
   * @memberof DeliveryOptions
   */
  initDeliverySettings () {
    this.templates = new DeliveryOptionsTemplates(this.deliveryModes)
  }

  /**
   * calls the delivery Modes api,
   * renders the delivery modes table and adds click listeners to each del. mode
   *
   * @memberof DeliveryOptions
   */
  onLoad () {
    if (this.deliveryModes.serviceId === '') return

    this.fetchDeliveryOptions(this.deliveryModes.serviceId)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(deliveryData => {
        if (deliveryData && deliveryData.deliverymodes) {
          this.renderTable(deliveryData.deliverymodes)
          this.addDeliveryModeClickListeners()
        }
      })
      .catch(console.error)
  }

  /**
   * fetchDeliveryMessage - given a serviceId
   * performs an external call that returns its delivery options
   *
   * @param {string} serviceId
   * @return {object}
   * @memberof DeliveryTimeInfo
   */
  fetchDeliveryOptions (serviceId) {
    const deliveryInfoUrl = this.deliveryModes.apiUrl + serviceId

    return fetch(deliveryInfoUrl)
  }

  /**
   * renders the table template with deliveryData from the delivery modes api
   * @param {[object]}
   *
   * @memberof DeliveryOptions
   */
  renderTable (deliveryData) {
    this.$deliveryOptions.innerHTML = this.templates.renderTable(deliveryData)
  }

  /**
   * Adds click listener on each of delivery modes rendered
   *
   * @memberof DeliveryOptions
   */
  addDeliveryModeClickListeners () {
    this.$links = document.querySelectorAll('.DeliveryOptions-link')

    this.$links.forEach((link) => {
      link.addEventListener('click', DeliveryOptions.onInfoClick, false)
    })
  }

  /**
   * Removes click listeners
   *
   * @memberof DeliveryOptions
   */
  removeDeliveryModeClickListeners () {
    if (!this.$links) {
      return
    }

    this.$links.forEach((link) => {
      link.removeEventListener('click', DeliveryOptions.onInfoClick, false)
    })
  }

  /**
   * Removes Load event listener
   *
   * @memberof DeliveryOptions
   */
  removeOnLoadListener () {
    if (!this.$deliveryOptions) {
      return
    }
    document.removeEventListener('DOMContentLoaded', this.onLoad, false)
  }

  /**
   * Dettaches all events to DOM elements.
   *
   * @memberof DeliveryOptions
   */
  destroy () {
    this.removeOnLoadListener()
    this.removeDeliveryModeClickListeners()
  }

  /**
   *
   *
   * @param {Event} e
   * @memberof DeliveryOptions
   */
  static onInfoClick (e) {
    const $title = closest(e.target, 'DeliveryOptions-title')
    const $info = $title.nextElementSibling.nextElementSibling

    $info.classList.toggle('is-toggled')
  }
}
