/**
 * Finds the closest parent with a given class name.
 * Returns null if no element found.
 *
 * @export
 * @param {HTMLElement} element
 * @param {string} className
 * @returns {Node|null}
 */
export function closest (element, className) {
  while ((element = element.parentElement) && !element.classList.contains(className)); // eslint-disable-line
  return element
}

/**
 * For example of use, go and see:
 * https://plainjs.com/javascript/traversing/get-siblings-of-an-element-40/
 *
 * @param {HTMLElement} element
 * @param {function} filter
 *
 */
export function getSiblings (el, filter) {
  const siblings = []
  let element = el.parentNode.firstChild
  do { // eslint-disable-line
    if (!filter || filter(element)) {
      siblings.push(element)
    }
  } while (element = element.nextSibling); // eslint-disable-line
  return siblings
}

/**
 * Toggles an attribute from true to false and viceversa.
 * Very handy for aria-* attributes.
 *
 * @export
 * @param {any} el
 * @param {any} attrName
 */
export function toggleAriaAttribute (el, attrName) {
  const attrValue = el.getAttribute(attrName)
  if (attrValue === 'true') {
    el.setAttribute(attrName, 'false')
  } else {
    el.setAttribute(attrName, 'true')
  }
}

/**
 * Check if the element is visible in the entire viewport.
 *
 * @export
 * @param {HTMlElement} element
 */
export function elementInViewport (element) {
  let left = element.offsetLeft
  const width = element.offsetWidth

  while (element.offsetParent) { // eslint-disable-line
    element = element.offsetParent; // eslint-disable-line
    left += element.offsetLeft
  }

  return (
    left >= window.pageXOffset &&
    (left + width) <= (window.pageXOffset + window.innerWidth)
  )
}
